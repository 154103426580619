import { addDays } from "date-fns";

export default {
	workflowTypes: {
		all: {
			key: "all",
			value: "All"
		},
		t1: {
			key: "t1",
			value: "T1"
		},
		t2: {
			key: "t2",
			value: "T2"
		},
		t3: {
			key: "t3",
			value: "T3"
		},
		t4: {
			key: "t4",
			value: "T4"
		},
		t5: {
			key: "t5",
			value: "T5"
		},
		hst: {
			key: "hst",
			value: "HST"
		},
		back_office: {
			key: "back_office",
			value: "Back Office"
		},
		sr_ed: {
			key: "sr_ed",
			value: "SR&ED"
		}
	},
	statuses: {
		not_ready: {
			key: "not_ready",
			value: "Not Ready (Inactive)",
			color: "#FFFFFF"
		},
		not_started: {
			key: "not_started",
			value: "Not Started",
			color: "rgba(215,215,215,0.40)"
		},
		ready_to_start_bk: {
			key: "ready_to_start_bk",
			value: "Ready to Start Bookkeeping",
			color: "#D0D0D0"
		},
		bk_in_progress: {
			key: "bk_in_progress",
			value: "Bookkeeping in Progress",
			color: "rgba(255,237,30,0.38)"
		},
		ready_to_start_ntr: {
			key: "ready_to_start_ntr",
			value: "Ready to Start NTR",
			color: "#D0D0D0"
		},
		ntr_in_progress: {
			key: "ntr_in_progress",
			value: "NTR in Progress",
			color: "#FEB6B2"
		},
		in_progress: {
			key: "in_progress",
			value: "In Progress",
			color: "rgba(255,237,30,0.38)"
		},
		waiting_on_info: {
			key: "waiting_on_info",
			value: "Waiting On Info",
			color: "rgba(141,255,229,0.60)"
		},
		ready_for_manager: {
			key: "ready_for_manager",
			value: "Ready For Manager",
			color: "rgba(255,167,23,0.50)"
		},
		ready_for_partner: {
			key: "ready_for_partner",
			value: "Ready For Partner",
			color: "#FA7777"
		},
		ready_for_client: {
			key: "ready_for_client",
			value: "Ready for Client",
			color: "rgba(224,16,161,0.40)"
		},
		sent_waiting_for_signature: {
			key: "sent_waiting_for_signature",
			value: "Sent Waiting For Signature",
			color: "rgba(144,19,254,0.40)"
		},
		signed: {
			key: "signed",
			value: "Signed",
			color: "rgba(75,158,255,0.60)"
		},
		e_filed: {
			key: "e_filed",
			value: "eFiled",
			color: "rgba(126,211,33,0.40)"
		},
		completed: {
			key: "completed",
			value: "Completed",
			color: "#D0D0D0"
		},
		inactive: {
			key: "inactive",
			value: "Inactive",
			color: "#D0D0D0"
		}
	},

	taskStatus: {
		pending: {
			key: "pending",
			value: "Pending"
		},
		ready: {
			key: "ready",
			value: "Ready"
		},
		in_progress: {
			key: "in_progress",
			value: "In Progress"
		},
		done: {
			key: "done",
			value: "Done"
		}
	},
	typeToFrequency: {
		t1: {
			value: "yearly",
			term: 12 //in months
		},
		t2: {
			value: "yearly",
			term: 12 //in months
		},
		t3: {
			value: "yearly",
			term: 12 //in months
		},
		t4: {
			value: "yearly",
			term: 12 //in months
		},
		t5: {
			value: "yearly",
			term: 12 //in months
		},
		hst: {
			value: "quarterly",
			term: 3 //in months
		}
	},
	orderQueries: {
		name: "name",
		team: "team",
		clientName: "clientName",
		status: "status",
		type: "type",
		notes: "notes",
		dueDate: "dueDate",
		dateIn: "dateIn",
		fiscalYearEnd: "fiscalYearEnd"
	},
	schedules: {
		"0 0 0 1 * *": {
			id: "everyMonth",
			name: "Every 1st of the month",
			schedule: "0 0 0 1 * *"
		},
		"0 0 0 * * 1": {
			id: "everyWeek",
			name: "Every Monday",
			schedule: "0 0 0 * * 1"
		}
	},
	defaultDateFunction: { dateFunction: "all", payload: {} },
	dateFunctions: {
		all: {
			id: "all",
			display: "All"
		},
		today: {
			id: "today",
			display: "Today"
		},
		yesterday: {
			id: "yesterday",
			display: "Yesterday"
		},
		lastXDays: {
			id: "lastXDays",
			display: "Last X Days",
			payload: [
				{
					name: "x",
					type: "number",
					default: 7
				}
			]
		},
		dateRange: {
			id: "dateRange",
			display: "Date Range",
			payload: [
				{
					name: "range",
					type: "dateRange",
					default: {
						startDate: new Date(),
						endDate: addDays(new Date(), 7),
						key: "selection"
					}
				}
			]
		}
	},
	columns: {
		name: {
			id: "name",
			display: "Name"
		},
		client: {
			id: "client",
			display: "Client"
		},
		type: {
			id: "type",
			display: "Type"
		},
		team: {
			id: "team",
			display: "Team"
		},
		users: {
			id: "users",
			display: "Users"
		},
		status: {
			id: "status",
			display: "Status"
		},
		taskProgress: {
			id: "taskProgress",
			display: "Task Progress"
		},
		lastNote: {
			id: "lastNote",
			display: "Last Note"
		},
		dateIn: {
			id: "dateIn",
			display: "Date In"
		},
		dueDate: {
			id: "dueDate",
			display: "Due Date"
		},
		fiscalYearEnd: {
			id: "fiscalYearEnd",
			display: "Fiscal Year End"
		}
	}
};
