import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import WComponent from "../WComponent";
import clsx from "clsx";

class TextFieldInput extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: props.showPassword || false // Initialize state
		};

		this.authInput = this.authInput.bind(this);
	}

	authInput() {
		let { title, containerClass, className, value, type, name, autoComplete, placeholder, style, onChange } = this.props;

		return (
			<div className={clsx("custom-text-field", containerClass)}>
				{title && <span className="custom-text-field__label">{title}</span>}
				<div className="custom-text-field__input-container">
					<input
						className={clsx("custom-text-field__input", className)}
						style={style}
						type={this.state.showPassword ? "text" : type || "text"} // Use state for showPassword
						name={name}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						autoComplete={autoComplete || "off"}
					/>
					{/* TODO: BUGGY -- comeback to this */}
					{/* {passwordViewableToggle && (
						<button
							className="custom-text-field__password-view-toggle"
							aria-label="toggle password visibility"
							onClick={() => {
								this.setState(prevState => ({
									showPassword: !prevState.showPassword // Toggle state
								}));
							}}
						>
							{this.state.showPassword ? <Icon.EyeOff color="#797979" /> : <Icon.Eye color="#797979" />}
						</button>
					)} */}
				</div>
			</div>
		);
	}

	render() {
		let { title, value, type, containerClass, className, name, onChange, disabled, autoComplete, onKeyDown, placeholder, view, style } = this.props;

		let { authInput } = this;

		if (view == "auth") {
			return authInput();
		}

		return (
			<div className={clsx("input-field", containerClass)}>
				{title && <span className="input-field__title">{title}</span>}
				<input
					style={style}
					type={type}
					className={clsx("input-field__input", className)}
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					autoComplete={autoComplete}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
				/>
			</div>
		);
	}
}

TextFieldInput.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string,
	onKeyDown: PropTypes.func,
	width: PropTypes.string
};

TextFieldInput.defaultProps = {
	type: "text"
};

export default TextFieldInput;
