import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import Board from "react-trello";
import EngagementTaskModal from "../../workflow/EngagementTemplates/eng_task_modal/EngagementTaskModal";
import EngagementTaskCard from "../eng_task_card/EngagementTaskCard";
import "./engagement-tasks.css";

const TaskList = ({ tasks, updateEngagementSnapshot }) => {
	const assignIndexToTasks = tasks => {
		return tasks.map((task, idx) => {
			if (typeof task.index === "undefined" || task.index === null) {
				return { ...task, index: idx };
			}
			return task;
		});
	};

	const getSortedTasks = engTasks => {
		if (!engTasks) {
			return [];
		}
		let tasks = engTasks;
		const tasksWithIndex = assignIndexToTasks(tasks);
		const sortedTasks = tasksWithIndex.sort((a, b) => a.index - b.index);
		return sortedTasks;
	};

	const [engTasks, setEngTasks] = useState([]);
	const [engagementTaskAction, setEngagementTaskAction] = useState("new");
	const [engTaskUpdateIndex, setEngTaskUpdateIndex] = useState(null);
	const [showEngagementTaskModal, setShowEngagementTaskModal] = useState(false);

	useEffect(() => {
		if (tasks) {
			setEngTasks(getSortedTasks(tasks));
		}
	}, []);

	const handleCardMove = (fromLaneId, toLaneId, cardId, newIndex) => {
		const tasks = [...engTasks];
		const oldIndex = parseInt(cardId, 10);
		const [movedTask] = tasks.splice(oldIndex, 1);

		tasks.splice(newIndex, 0, movedTask);
		const updatedTasks = tasks.map((task, index) => ({ ...task, index }));
		setEngTasks(updatedTasks);
		updateEngagementSnapshot(updatedTasks);
	};

	const onRemoveTask = index => {
		let prevTasks = [...engTasks];
		prevTasks.splice(index, 1);
		setEngTasks(prevTasks);
		updateEngagementSnapshot(prevTasks);
	};

	const onUpdateEngTask = index => {
		setEngagementTaskAction("update");
		setEngTaskUpdateIndex(index);
		setShowEngagementTaskModal(true);
	};

	const onNewEngTask = () => {
		setEngagementTaskAction("new");
		setEngTaskUpdateIndex(null);
		setShowEngagementTaskModal(true);
	};

	const lane = {
		id: "taskLane",
		cards: engTasks.map((task, index) => ({
			id: index.toString(),
			task,
			index,
			onRemoveTask,
			onUpdateEngTask
		}))
	};

	return (
		<>
			<EngagementTaskModal
				show={showEngagementTaskModal}
				action={engagementTaskAction}
				onClose={() => {
					setEngagementTaskAction("new");
					setEngTaskUpdateIndex(null);
					setShowEngagementTaskModal(false);
				}}
				engTasks={engTasks}
				taskToUpdateIndex={engTaskUpdateIndex}
				updateEngagementTasks={updateEngagementSnapshot}
			/>
			<div className="re-tasks">
				<div className="re-tasks__task-list">
					<Board
						data={{ lanes: [lane] }}
						components={{ Card: EngagementTaskCard }}
						laneStyle={{
							backgroundColor: "transparent"
						}}
						draggable={true}
						onCardMoveWithinLane={handleCardMove}
						onCardMoveAcrossLanes={handleCardMove}
					/>
				</div>

				<div className="etm__tasks__cta">
					<div className="etm__tasks__cta__title">Click the plus button to add a Task</div>
					<Icon.PlusCircle className="etm__tasks__cta__button" size={28} color="#385395" onClick={onNewEngTask} />
				</div>
			</div>
		</>
	);
};

export default TaskList;
