import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";

import WComponent from "../../common/WComponent";

import "./client-group-modal.css";
import ClientService from "../../../services/ClientService";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import ClientAssigner from "../../common/client_assigner/ClientAssigner";

export default class ClientGroupModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			clientGroupId: "new",
			clientGroupName: "",

			assignedClients: [],

			show: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, clientGroupId } = this.props;

		await this.clear();

		await this.update({
			show,
			clientGroupId
		});

		if (clientGroupId !== "new") {
			await this.fetchClientGroup();
		}
	};

	clear = async () => {
		await this.update({
			clientGroupId: "new",
			clientGroupName: "",
			assignedClients: []
		});
	};

	fetchClientGroup = async () => {
		let { clientGroupId } = this.state;

		let clientGroup = await ClientService.fetchClientGroup({ clientGroupId });

		let clientsInGroup = clientGroup.clients.map(client => {
			return {
				client_id: client.id,

				// For rendering
				clientName: client.name
			};
		});

		await this.update({
			clientGroupName: clientGroup.name,
			assignedClients: clientsInGroup
		});
	};

	onChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		this.update({
			[target.name]: value
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onAssignedClientsChanged = async ({ selectedClients }) => {
		await this.update({
			assignedClients: selectedClients
		});
	};

	onSave = async () => {
		let { loading, clientGroupId, clientGroupName, assignedClients } = this.state;

		if (loading) {
			return;
		}

		await this.update({
			loading: true
		});

		if (clientGroupId !== "new") {
			await ClientService.updateClientGroup({ clientGroupId, name: clientGroupName, assignedClients });
		} else {
			await ClientService.createClientGroup({ name: clientGroupName, assignedClients });
		}

		await this.update({
			loading: false
		});

		this.onClose();
	};

	onDelete = async () => {
		let { loading, clientGroupId } = this.state;

		if (loading) {
			return;
		}

		await this.update({
			loading: true
		});

		await ClientService.updateClientGroup({ clientGroupId, status: "deleted" });

		await this.update({
			loading: false
		});

		this.onClose();
	};

	render() {
		let { show, clientGroupId, clientGroupName, assignedClients } = this.state;
		let { onClose } = this.props;

		let title = `Group: ${clientGroupName}`;

		if (clientGroupId === "new") {
			title = `New Group: ${clientGroupName}`;
		}

		return (
			<Modal open={show} onClose={onClose} center>
				<div className="modal-title">{title}</div>
				<div className="modal-content">
					<TextFieldInput
						title="Client Group Name"
						name="clientGroupName"
						onChange={this.onChange}
						value={clientGroupName || ""}
						autoComplete="off"
					/>
					<ClientAssigner onChange={this.onAssignedClientsChanged} clients={assignedClients} />
				</div>
				<div className="btn-group">
					<div className="btn btn--icon" onClick={this.onDelete}>
						<Icon.Trash2 size={22} />
					</div>
					<div className="btn" onClick={this.onSave}>
						Save
					</div>
					<div className="btn" onClick={onClose}>
						Cancel
					</div>
				</div>
			</Modal>
		);
	}
}
