import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";

/**
 * @description ConfirmationModal component for confirming selection.
 * @class ConfirmationModal
 * @extends {React.PureComponent<ConfirmationModal.propTypes>}
 */
class ConfirmationModal extends React.PureComponent {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		return (
			<Modal open={this.props.show} onClose={this.props.onClose} center>
				<div className="modal-title">{this.props.title}</div>
				<div className="modal-content">{this.props.warning}</div>
				<div className="btn-group">
					<div
						onClick={() =>
							this.props.onClose({
								confirmed: true,
								proxyState: this.props.proxyState
							})
						}
						className="btn"
					>
						Yes
					</div>
					<div
						onClick={() =>
							this.props.onClose({
								confirmed: false,
								proxyState: this.props.proxyState
							})
						}
						className="btn"
					>
						No
					</div>
				</div>
			</Modal>
		);
	}
}

ConfirmationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	proxyState: PropTypes.object
};
export default ConfirmationModal;
