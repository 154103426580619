// External Services
import React, { Component } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Services
import NoteService from "../../services/NoteService";
import UtilityService from "../../services/UtilityService";

// Common Components
import Button from "../common/button/Button";
import TextAreaField from "../common/text_area_field/TextAreaField";

// Styling
import "./floating-notes.css";

const variants = {
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.2
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 0.1
		}
	}
};

class FloatingNotes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			leftPos: 0,
			topPos: 0,

			engagementName: "",
			engagementId: null,
			clientId: null,

			notes: [],

			loading: false,

			newNoteContent: ""
		};

		this.floatingRef = null;
		this.noteModal = null;

		this.inputRef = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.onHandleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	onHandleClick = e => {
		if (this.floatingRef && this.floatingRef.contains(e.target)) {
			// the click is in the component
			return;
		}
		this.onClose();
	};

	onChange = () => {
		let { onChangeCallback, notes } = this.state;

		if (typeof onChangeCallback === "function") {
			onChangeCallback(notes.reverse());
		}

		this.onClose();
	};

	onClose = async () => {
		await this.update({ show: false });

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	forceShow = async () => {
		await this.update({
			show: true
		});
	};

	fetchNotes = async () => {
		let { engagementId } = this.state;

		let notes = await NoteService.fetchNotes({ engagementId });

		await this.update({
			notes,
			engagementId
		});
	};

	setEngagementId = async engagementId => {
		await this.update({
			engagementId,
			loading: true
		});

		await this.fetchNotes();

		if (this.inputRef && this.inputRef.current) {
			this.inputRef.current.focus();
		}

		await this.update({
			loading: false
		});
	};

	setClientId = async clientId => {
		await this.update({ clientId });
	};

	setEngagementName = engagementName => {
		this.update({
			engagementName
		});
	};

	setPosition = async ({ left, top }) => {
		let update = {};
		if (typeof left !== "undefined") update.leftPos = left;
		if (typeof top !== "undefined") update.topPos = top;
		await this.update(update);
	};

	setOnChangeCallback = async callback => {
		await this.update({ onChangeCallback: callback });
	};

	renderNote(note) {
		return (
			<div className="floating-notes__item" onClick={() => this.onNoteSelect(note)}>
				<div className="floating-notes__item__content">{note.content}</div>
				<div className="floating-notes__item__metadata">
					{note.User.first_name} {note.User.last_name} • {UtilityService.getRelativeDate(note.created_at)}
				</div>
			</div>
		);
	}

	renderEmptyNotes() {
		let { notes } = this.state;

		if (notes.length > 0) {
			return null;
		}

		return (
			<div className="floating-notes floating-notes--no-flex">
				<div className="floating-notes__blurb">No notes on this engagement</div>
			</div>
		);
	}

	renderNotes() {
		let { notes } = this.state;

		if (notes.length === 0) {
			return null;
		}

		return (
			<div className="floating-notes">
				<div className="floating-notes__title">Notes:</div>
				{notes.map(note => this.renderNote(note))}
			</div>
		);
	}

	onNoteSave = async () => {
		let { newNoteContent, engagementId, clientId } = this.state;

		let success = await NoteService.createNote({
			content: newNoteContent,
			engagementId,
			clientId,
			isPinned: false
		});

		await this.fetchNotes();

		this.update({
			loading: false,
			newNoteContent: ""
		});

		this.onChange();
	};

	onNewNoteChange = async event => {
		let value = event.target.value;

		await this.update({
			newNoteContent: value
		});
	};

	renderActions() {
		let { newNoteContent } = this.state;

		return (
			<div className="notes-container__actions">
				<TextAreaField setRef={this.inputRef} title="New Note" type="text" name="content" value={newNoteContent} onChange={this.onNewNoteChange} />

				<div className="btn-group btn-group--spacing">
					<Button text="Add a note" view="secondary" onClick={this.onNoteSave} />
				</div>
			</div>
		);
	}

	generatePositionStyling = () => {
		let { leftPos, topPos } = this.state;

		let style = {};

		if (topPos || leftPos) {
			if (topPos) {
				style.top = `${topPos}px`;
			}
			if (leftPos) {
				style.left = `${leftPos}px`;
			}
		} else {
			style.bottom = "20px";
		}

		return style;
	};

	render() {
		let { show, engagementName, loading } = this.state;

		let boxStyling = this.generatePositionStyling();

		return (
			<>
				<AnimatePresence>
					{show && (
						<motion.div
							key="container"
							className="notes-container"
							style={boxStyling}
							ref={ref => (this.floatingRef = ref)}
							initial="exit"
							animate="enter"
							exit="exit"
							variants={variants}
						>
							<div className="notes-container__name">{engagementName}</div>

							{this.renderActions()}

							{!loading && (
								<>
									{this.renderEmptyNotes()}
									{this.renderNotes()}
								</>
							)}

							{loading && <div className="notes-container__loader">Loading ...</div>}
						</motion.div>
					)}
				</AnimatePresence>
			</>
		);
	}
}

export default FloatingNotes;
