import moment from "moment";
import Kichiri from "./KichiriService";
import UserService from "./UserService";
import WORKFLOW from "../constants/Workflow";
import UtilityService from "./UtilityService";
import ConfigurationService from "../services/ConfigurationService";
import { DEFAULT_VIEWS } from "../constants/Views";

const EngagementService = {
	/**
	 * Filter all the tasks based on the search search
	 *
	 * @param {String} searchTerm
	 * @param {Array} tasks
	 * @return {Array}
	 */
	searchTasks({ searchTerm, tasks }) {
		searchTerm = searchTerm.toLowerCase();

		return tasks.filter(task => {
			let userLastName = task.User ? task.User.last_name.toLowerCase() : "unassigned";
			let userFirstName = task.User ? task.User.first_name.toLowerCase() : "unassigned";

			let name = task.name || "untitled";
			let description = task.description || "no description";
			let notes = task.notes || "no notes";

			let deadline = moment(task.deadline).format("MMMM Do YYYY @ h:mma") || "";

			return (
				name.toLowerCase().indexOf(searchTerm) !== -1 ||
				description.toLowerCase().indexOf(searchTerm) !== -1 ||
				userFirstName.toLowerCase().indexOf(searchTerm) !== -1 ||
				userLastName.toLowerCase().indexOf(searchTerm) !== -1 ||
				notes.toLowerCase().indexOf(searchTerm) !== -1 ||
				task.status.toLowerCase().indexOf(searchTerm) !== -1 ||
				deadline.toLowerCase().indexOf(searchTerm) !== -1
			);
		});
	},

	/**
	 * Update a single engagement
	 *
	 * @param {Object} request
	 * @return {Promise}
	 */
	async updateEngagement({ request }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.update(request, {}, authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Deletes an engagement
	 * @param {Number} engagementId
	 * @returns {Boolean}
	 */
	async deleteEngagement({ engagementId }) {
		let { authToken } = UserService.getUserData();

		try {
			await Kichiri.engagement.delete({ engagementId }, {}, authToken);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Fetch engagements
	 *
	 * @param {Object} params
	 * @returns {Object}
	 */
	async fetchEngagements(params) {
		let userData = UserService.getUserData();

		if (params.assignedUsers) {
			params.assignedUsers = JSON.stringify(params.assignedUsers);
		}

		if (params.assignedClients) {
			params.assignedClients = JSON.stringify(params.assignedClients);
		}

		if (params.assignedClientGroups) {
			params.assignedClientGroups = JSON.stringify(params.assignedClientGroups);
		}

		if (params.assignedTeams) {
			params.assignedTeams = JSON.stringify(params.assignedTeams);
		}

		// if (params.assignedFlowStates) {
		// 	params.assignedFlowStates = JSON.stringify(params.assignedFlowStates);
		// }

		if (params.yearEnd) {
			params.yearEnd = JSON.stringify(params.yearEnd);
		}

		if (params.dueDate) {
			params.dueDate = JSON.stringify(params.dueDate);
		}

		if (params.lastActivityDate) {
			params.lastActivityDate = JSON.stringify(params.lastActivityDate);
		}

		if (params.dateIn) {
			params.dateIn = JSON.stringify(params.dateIn);
		}

		let updatedFlowStates = params.selectedFlowStates.map(flowState => ({ flowStateId: flowState.flowStateId }));

		if (params.selectedFlowStates) {
			params.selectedFlowStates = JSON.stringify(updatedFlowStates);
		}

		let query = {
			assignedUsers: "[]",
			assignedTeams: "[]",
			assignedClients: "[]",
			assignedClientGroups: "[]",
			// assignedFlowStates: "[]",
			selectedFlowStates: "[]",
			companyId: userData.companyId,
			clientId: 0,
			flowId: null,
			limit: 50,
			offset: 0,
			search: null,
			shouldShowInactive: false,
			sort: [{ field: WORKFLOW.orderQueries.fiscalYearEnd, order: "desc" }],
			countOnly: false
		};

		if (params) {
			query = Object.assign(query, params);
		}

		try {
			let response = await Kichiri.engagement.list({}, query, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches list of flows
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async fetchFlows({ searchTerm = "" }) {
		let userData = UserService.getUserData();

		let query = {
			companyId: userData.companyId
		};

		if (searchTerm) {
			query.searchTerm = searchTerm;
		}

		try {
			let response = await Kichiri.flow.list({}, query, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches a flow
	 * @param {Number} flowId
	 * @returns {Object}
	 */
	async fetchFlow({ flowId }) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.flow.get({ flowId }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches list of flow states
	 * @param {Number} flowId
	 * @param {String} flowCode
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async fetchFlowStates({ flowId, flowCode, searchTerm }) {
		if (!flowCode) {
			return [];
		}

		let userData = UserService.getUserData();

		let query = {
			companyId: userData.companyId,
			flowId,
			flowCode,
			searchTerm
		};

		try {
			let response = await Kichiri.flow.listStates({}, query, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetch and engagement
	 * @param {Number} engagementId
	 * @returns {Object}
	 */
	async fetchEngagement(engagementId) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.get(
				{
					engagementId
				},
				{},
				userData.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Create multiple engagments
	 * @param {Number} clientIds
	 * @param {Object} flowIds
	 * @returns {Object}
	 */
	async multiCreateEngagements({ clientIds, flowIds }) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.multiCreate(
				{
					clientIds,
					flowIds
				},
				{},
				userData.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Create an engagement
	 * @param {String} name
	 * @param {Number} teamId
	 * @param {Number} clientId
	 * @param {Number} flowId
	 * @param {Number} flowStateId
	 * @param {Date} dueDate
	 * @param {Date} dateIn
	 * @param {Date} fiscalYearEnd
	 * @param {Array} assignedUsers
	 * @returns
	 */
	async create({ name, teamId, clientId, flowId, flowStateId, dueDate, dateIn, fiscalYearEnd, assignedUsers, tasks, rrule, relativeDueDate }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.create(
				{
					name,

					teamId,
					clientId,
					flowId,
					flowStateId,

					dueDate,
					dateIn,
					fiscalYearEnd,

					assignedUsers,
					tasks,

					rrule,
					// If there is an rrule string in creating the engagement, then it is a recurring engagement
					isRecurring: UtilityService.isNotNullish(rrule) ? rrule.length > 0 : false,
					relativeDueDate
				},
				{},
				authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	async fetchView({ viewId }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.view.get(
				{
					viewId
				},
				{},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	async fetchViews({ type }) {
		let { authToken, userId } = UserService.getUserData();

		try {
			let response = await Kichiri.view.list(
				{},
				{
					userId,
					type
				},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	async createView({ name, userIds, teamIds, schema, isShared, assignedUsers, assignedTeams, type = "engagement" }) {
		let { authToken, companyId } = UserService.getUserData();

		try {
			let response = await Kichiri.view.create(
				{
					name,
					userIds,
					teamIds,
					schema,
					isShared,
					companyId,
					assignedUsers,
					assignedTeams,
					type
				},
				{},
				authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch an engagement
	 * @param {Number} engagementId
	 * @returns {Object}
	 */
	async get({ engagementId }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.get({ engagementId }, {}, authToken);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates an engagement
	 * @param {Number} engagementId
	 * @param {Number} teamId
	 * @param {Number} clientId
	 * @param {Number} flowStateId
	 * @param {String} name
	 * @param {Date} dueDate
	 * @param {Date} dateIn
	 * @param {Date} fiscalYearEnd
	 * @param {String} notes
	 * @param {Array} assignedUsers
	 * @returns {Object}
	 */
	async update({ engagementId, teamId, clientId, flowStateId, name, dueDate, dateIn, fiscalYearEnd, notes, assignedUsers }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.engagement.update(
				{ engagementId, teamId, clientId, flowStateId, name, dueDate, dateIn, fiscalYearEnd, notes, assignedUsers },
				{},
				authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	async updateView({ viewId, schema, name, isShared, userIds, teamIds, status, type = "engagement" }) {
		let { authToken, companyId } = UserService.getUserData();

		try {
			let response = await Kichiri.view.update(
				{
					viewId,
					name,
					userIds,
					teamIds,
					schema,
					isShared,
					companyId,
					status,
					type
				},
				{
					viewId
				},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * XXX - Temporary custom function to derive due dates for different flow types
	 * We don't have an elegant solution right now, so for now we are going to write custom code for this
	 *
	 * @param {String} flowCode
	 * @param {Date} fiscalYearEnd
	 * @param {String} frequency
	 */
	deriveDueDateForEngagement({ flowCode, fiscalYearEnd, frequency }) {
		let date = moment();

		if (flowCode === "t1") {
			let newDate = moment(fiscalYearEnd);

			newDate.add(1, "years");
			newDate.set({ month: 3, date: 30 });

			date = newDate;
		} else if (flowCode === "t2") {
			date = moment(fiscalYearEnd).add(6, "months");
		} else if (flowCode === "t3") {
			date = moment(fiscalYearEnd).add(90, "days");
		} else if (flowCode === "t4" || flowCode === "t5") {
			let newDate = moment(fiscalYearEnd);

			newDate.add(1, "years");
			newDate.set({ month: 1, date: 28 });

			date = newDate;
		} else if (flowCode === "hst") {
			// Default behaviour
			date = moment(fiscalYearEnd).add(3, "months");

			if (frequency === "annual") {
				date = moment(fiscalYearEnd).add(3, "months");
			} else if (frequency === "quarterly") {
				date = moment(fiscalYearEnd).add(1, "months");
			} else if (frequency === "monthly") {
				date = moment(fiscalYearEnd).add(1, "months");
			}
		}

		return date.toDate();
	},

	async fetchEngagementTemplates({ searchTerm = "", sortField, sortOrder }) {
		try {
			let { authToken, companyId } = UserService.getUserData();

			let { data: templates } = await Kichiri.engagement.listTemplates({}, { companyId, searchTerm, sortField, sortOrder }, authToken);

			return templates;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	async fetchEngagementTemplate({ engagementTemplateId }) {
		try {
			let { authToken } = UserService.getUserData();

			let { data: template } = await Kichiri.engagement.getTemplate({ engagementTemplateId }, {}, authToken);

			return template;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	async updateEngagementTemplate({ engagementTemplateId, teamId, flowId, flowStateId, name, notes, fiscalYearEnd, dueDate, dateIn, tasks, users, status }) {
		try {
			let { authToken, companyId } = UserService.getUserData();

			let { data: template } = await Kichiri.engagement.updateTemplate(
				{ engagementTemplateId, companyId, teamId, flowId, flowStateId, name, notes, fiscalYearEnd, dueDate, dateIn, tasks, users, status },
				{},
				authToken
			);

			return template;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	async createEngagementTemplate({ teamId, flowId, flowStateId, name, notes, fiscalYearEnd, dueDate, dateIn, tasks, users, status }) {
		try {
			let { authToken, companyId } = UserService.getUserData();

			let { data: template } = await Kichiri.engagement.createTemplate(
				{ companyId, teamId, flowId, flowStateId, name, notes, fiscalYearEnd, dueDate, dateIn, tasks, users, status },
				{},
				authToken
			);

			return template;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update Recurring Engagement
	 *
	 * @param {Number} recurringEngagementId
	 * @param {String} rrule
	 * @returns
	 */
	async updateRecurringEngagement({ recurringEngagementId, rrule, engagementSnapshot }) {
		try {
			let { authToken } = UserService.getUserData();

			let { data: recurringEngagement } = await Kichiri.engagement.updateRecurringEngagement(
				{ recurringEngagementId, rrule, engagementSnapshot },
				{},
				authToken
			);

			return recurringEngagement;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * A request to export engagements
	 * @param {number} clientId
	 * @param {number} flowId
	 * @param {Array} assignedUsers
	 * @param {Array} assignedTeams
	 * @param {Array} assignedFlowStates
	 * @param {Date} yearEnd
	 * @param {Date} dueDate
	 * @param {Date} dateIn
	 * @param {String} search
	 * @param {Number} limit
	 * @param {Number} offset
	 * @param {Array} sort
	 * @param {Array<Object>} selectedFlowStates
	 */
	async exportEngagements({
		clientId,
		flowId,
		assignedUsers,
		assignedTeams,
		selectedFlowStates,
		yearEnd,
		dueDate,
		dateIn,
		search,
		limit,
		offset,
		sort,
		lastActivityDate
	}) {
		let { authToken } = UserService.getUserData();

		let query = {
			clientId,
			flowId,
			assignedUsers,
			assignedTeams,
			selectedFlowStates,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			lastActivityDate
		};

		let response = await fetch(`${ConfigurationService.resolveApi().rest}/engagement/export`, {
			method: "POST",
			body: JSON.stringify(query),
			headers: {
				"Content-Type": "application/json",
				authorization: authToken
			}
		});

		let blob = await response.blob();

		// Tis a ghetto little thing
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.href = url;
		a.target = "_blank";
		a.download = `workflow-engagement-export-${moment().format("YYYY-MM-DD")}.csv`;
		document.body.appendChild(a);
		a.click();
		a.remove();
	},

	/**
	 * Checks if a specific column is present in the view's columns
	 *
	 * @param {Object} columns - An object representing the columns in the view
	 * @param {string} columnId - The ID of the column to check for
	 * @returns {boolean} - True if the column is present or if columns is empty, false otherwise
	 */
	viewHasColumn({ columns, columnId }) {
		// eslint-disable-next-line no-undef
		if (_.isEmpty(columns)) {
			return true;
		}
		return columns[columnId];
	},

	/**
	 * Indicates if the view is a default static view (eg, All, or Assigned to Me)
	 *
	 * @param {String} viewCode
	 * @returns {Boolean}
	 */
	isDefaultViewStatic({ viewCode }) {
		return DEFAULT_VIEWS.find(defaultView => defaultView.code === viewCode);
	},

	/**
	 * Returns a list of flow states but flatten to make filtering and readabliity better for a specified flow
	 *
	 * @param {String} flowCode
	 * @returns {Array<Object>}
	 */
	async flattenFlowStates({ flowCode }) {
		let flowStates = await this.fetchFlowStates({ flowCode });

		flowStates = flowStates.map(flowState => ({
			flowId: flowState.flow_id,
			flowName: flowState.Flow.name,
			flowStateId: flowState.id,
			flowStateName: flowState.name,
			flowStateColor: flowState.color,
			code: flowState.Flow.code
		}));
		return flowStates;
	}
};

export default EngagementService;
