import React from "react";
import clsx from "clsx";

import CLIENTS from "../../../constants/Clients";

import UserService from "../../../services/UserService";
import ClientService from "../../../services/ClientService";
import EngagementService from "../../../services/EngagementService";
import CustomFieldService from "../../../services/CustomFieldService";
import UtilityService from "../../../services/UtilityService";
import TaskService from "../../../services/TaskService";
import PlaidService from "../../../services/PlaidService";

import WComponent from "../WComponent";

import "./searchable-dropdown.css";

const PARTNER_GROUP_ID = 1;

const TYPES = {
	clients: "clients",
	partners: "partners",
	users: "users",
	roles: "roles",
	teams: "teams",
	staff: "staff",
	bookeeper: "bookeeper",
	flows: "flows",
	flowStates: "flowStates",
	customFieldList: "customFieldList",
	status: "status",
	taskStatuses: "taskStatuses",
	engTemplate: "engTemplate",
	clientGroups: "clientGroups",
	plaidAccounts: "plaidAccounts"
};

const KEYS = {
	up: 38,
	down: 40,
	enter: 13,
	tab: 9,
	esc: 27,
	semiColon: 186,
	backspace: 8
};

class SearchableDropdown extends WComponent {
	constructor(props) {
		super(props);

		// props
		let { selectedName, flowCode, customFieldId, showAll, prependUnassigned } = props;

		// props: {
		//     alwaysShow,// Will always show the list regardless of focus
		//     showAll, // Will show all options on focus (through click or tab)
		//     prependUnassigned,// Will prepend an "unassigned" option at the start of the list
		//     clearOnSelect // Will clear the field once an item is selected
		// }

		this.state = {
			show: false,
			hoverIndex: 0,
			isListHover: false,

			type: "clients",

			search: selectedName || "",
			results: [],

			flowCode: flowCode,

			customFieldId,
			customField: null,

			showAll,
			prependUnassigned,
			isFocus: false,

			firstFocus: false,

			searchListStyles: {}
		};

		this.onSelect = this.onSelect.bind(this);

		this.searchListRef = null;
		this.inputComponentRef = null;
	}

	componentDidMount = () => {
		let { alwaysShow } = this.props;

		if (alwaysShow) {
			this.searchResults();
		}
	};

	componentDidUpdate = prevProps => {
		let { selectedName, flowCode } = this.props;
		if (prevProps.selectedName !== selectedName) {
			this.update({
				search: selectedName
			});
		}

		if (prevProps.flowCode !== flowCode) {
			this.update({
				flowCode
			});
		}
	};

	searchClients = async () => {
		let { search } = this.state;

		try {
			let response = await ClientService.searchClients({ search });

			await this.update({
				results: response.data || [],
				firstFocus: false
			});
		} catch (error) {
			console.log(error);
		}
	};

	searchUsers = async ({ groupId, defaultValue = null }) => {
		let { search, firstFocus, showAll, prependUnassigned } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let users = await UserService.searchUsers({ searchTerm, groupId });

		let getDefaultRoleFormatted = user => {
			return UtilityService.isNullish(user.defaultRole)
				? undefined
				: {
						id: user.defaultRole.id,
						name: user.defaultRole.name
				  };
		};

		users = users.map(u => {
			return {
				id: u.id,
				name: `${u.first_name} ${u.last_name}`,
				defaultRole: getDefaultRoleFormatted(u)
			};
		});

		if (defaultValue) {
			users.unshift({
				id: 0,
				name: defaultValue
			});
		}

		if (prependUnassigned && !defaultValue) {
			users.unshift({
				id: 0,
				name: "Unassigned"
			});
		}

		await this.update({
			results: users,
			firstFocus: false
		});
	};

	searchEngTemplates = async () => {
		let engagementTemplates = await EngagementService.fetchEngagementTemplates({ sortField: "", sortOrder: "", searchTerm: "" });

		await this.update({
			results: engagementTemplates,
			firstFocus: false
		});
	};

	searchRoles = async () => {
		let { search, showAll, firstFocus } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let roles = await UserService.fetchRoles({ searchTerm });

		await this.update({
			results: roles,
			firstFocus: false
		});
	};

	searchTeams = async () => {
		let { search, prependUnassigned, firstFocus, showAll } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let teams = await UserService.fetchTeams({ search: searchTerm });

		if (prependUnassigned) {
			teams.unshift({
				id: 0,
				name: "Select a team ..."
			});
		}

		await this.update({
			results: teams,
			firstFocus: false
		});
	};

	searchFlows = async () => {
		let {
			search,
			// prependUnassigned, // TODO # W-345 - commented out as it is not used
			firstFocus,
			showAll
		} = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let flows = await EngagementService.fetchFlows({ searchTerm });

		// if (prependUnassigned) {
		// 	flowStates.unshift({
		// 		key: 0,
		// 		value: "Unassigned"
		// 	});
		// }

		await this.update({
			results: flows,
			firstFocus: false
		});
	};

	searchFlowStates = async () => {
		let {
			search,
			flowCode,
			//prependUnassigned, // TODO # W-345 - commented out as it is not used
			firstFocus,
			showAll
		} = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let flowStates = await EngagementService.fetchFlowStates({ flowCode, searchTerm });

		// if (prependUnassigned) {
		// 	flowStates.unshift({
		// 		key: 0,
		// 		value: "Unassigned"
		// 	});
		// }

		await this.update({
			results: flowStates,
			firstFocus: false
		});
	};

	searchCustomFieldList = async () => {
		let { search, customFieldId, customField, showAll, firstFocus } = this.state;

		if (!customField) {
			customField = await CustomFieldService.fetchField({ fieldId: customFieldId });

			await this.update({
				customField
			});
		}

		let options = [];

		try {
			options = JSON.parse(customField.options);
		} catch (error) {
			console.log(error);
		}

		let searchTerm = firstFocus && showAll ? "" : search;

		let filteredOptions = options.filter(option => {
			return option.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
		});

		filteredOptions = filteredOptions.map((option, index) => {
			return {
				id: index,
				name: option
			};
		});

		await this.update({
			results: filteredOptions,
			firstFocus: false
		});
	};

	searchTaskStatuses = async () => {
		let { search, firstFocus, showAll } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let taskStatuses = await TaskService.fetchTasksStatuses({ search: searchTerm });

		await this.update({
			results: taskStatuses,
			firstFocus: false
		});
	};

	searchStatus = async () => {
		const transformedStatuses = CLIENTS.statuses.map(status => ({
			id: status.key,
			name: status.value
		}));

		await this.update({
			results: transformedStatuses
		});
	};

	searchClientGroups = async () => {
		let { search, prependUnassigned, firstFocus, showAll } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let clientGroups = await ClientService.fetchClientGroups({ search: searchTerm });

		if (prependUnassigned) {
			clientGroups.unshift({
				id: 0,
				name: "Select a Client Group ..."
			});
		}

		await this.update({
			results: clientGroups,
			firstFocus: false
		});
	};

	searchPlaidAccounts = async () => {
		let { clientId } = this.props;
		let { search, prependUnassigned, firstFocus, showAll } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		let plaidAccounts = await PlaidService.fetchAccounts({ searchTerm, clientId });

		if (prependUnassigned) {
			plaidAccounts.unshift({
				id: 0,
				name: "Select a bank account ..."
			});
		}

		await this.update({
			results: plaidAccounts,
			firstFocus: false
		});
	};

	onSearchChange = async e => {
		let { showAll } = this.state;

		let value = e.target.value;

		await this.update({
			search: value,
			show: showAll || (value.length > 0 && !showAll),
			hoverIndex: 0
		});

		await this.searchResults();
	};

	searchResults = () => {
		let { type } = this.props;

		if (type === TYPES.clients) {
			this.searchClients();
		} else if (type === TYPES.users) {
			this.searchUsers({});
		} else if (type === TYPES.partners) {
			this.searchUsers({ groupId: 1, defaultValue: "All Partners" });
		} else if (type === TYPES.roles) {
			this.searchRoles();
		} else if (type === TYPES.teams) {
			this.searchTeams();
		} else if (type === TYPES.staff) {
			this.searchUsers({ groupId: 2, defaultValue: "All Staff" });
		} else if (type === TYPES.bookeeper) {
			this.searchUsers({ groupId: 3, defaultValue: "All Bookeepers" });
		} else if (type === TYPES.flows) {
			this.searchFlows();
		} else if (type === TYPES.flowStates) {
			this.searchFlowStates();
		} else if (type === TYPES.partners) {
			this.searchUsers({ groupId: PARTNER_GROUP_ID });
		} else if (type === TYPES.engTemplate) {
			this.searchEngTemplates();
		} else if (type === TYPES.customFieldList) {
			this.searchCustomFieldList();
		} else if (type === TYPES.taskStatuses) {
			this.searchTaskStatuses();
		} else if (type === TYPES.clientGroups) {
			this.searchClientGroups();
		} else if (type === TYPES.plaidAccounts) {
			this.searchPlaidAccounts();
		} else if (type === TYPES.status) {
			this.searchStatus();
		}
	};

	clear = async () => {
		await this.update({
			show: false,
			search: ""
		});
	};

	async onSelect({ item }) {
		let { clearOnSelect } = this.props;

		if (this.props.onSelect) {
			this.props.onSelect(item);
		}

		await this.update({
			show: false,
			search: clearOnSelect ? "" : item.name
		});
	}

	onInputFocus = async () => {
		let { type } = this.props;
		let { showAll } = this.state;

		await this.update({
			isFocus: true,
			show: showAll,
			// show: type === TYPES.plaidAccounts && !showAll,
			firstFocus: true
		});

		this.searchResults();
	};

	onInputBlur = async event => {
		await UtilityService.timeout(100);

		await this.update({
			show: false,
			isFocus: false
		});
	};

	onKeyDown = async e => {
		let { hoverIndex, results } = this.state;

		// If the user hits the ENTER key, select the current item
		if (e.keyCode === KEYS.enter) {
			e.preventDefault();
			this.onSelect({ item: results[hoverIndex] });
		}

		// If the user hits the UP arrow while searching
		else if (e.keyCode === KEYS.up) {
			e.preventDefault();

			// If the selector is at the top of the a list, jump to the bottom
			if (hoverIndex === 0) {
				hoverIndex = results.length - 1;
			}
			// In all other cases move the selector up by 1 chat
			else {
				hoverIndex--;
			}
		}
		// If the user hits the DOWN arrow while searching
		else if (e.keyCode === KEYS.down) {
			e.preventDefault();

			// And the selector is on the last item on the list, move to the top of the list
			if (hoverIndex === results.length - 1) {
				hoverIndex = 0;
			}
			// Otherwise move the selector down by 1 item
			else {
				hoverIndex++;
			}
		} else if (e.keyCode === KEYS.esc) {
			setTimeout(() => {
				this.update({
					isFocus: false,
					show: false
				});
			}, 100);
		}

		await this.update({ hoverIndex, isListHover: false });

		if (this.currentResult) {
			this.currentResult.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
		}
	};

	onMouseEnter = async index => {
		await this.update({
			hoverIndex: index
		});
	};

	onMouseLeave = async index => {
		await this.update({
			hoverIndex: index
		});
	};

	createIndexReference = (index, ref) => {
		let { hoverIndex } = this.state;

		if (hoverIndex === index) {
			this.currentResult = ref;
		}
	};

	render() {
		let { searchPlaceHolder, search, show, results, hoverIndex, isListHover, searchListStyles } = this.state;
		let { containerClass, className, title, alwaysShow } = this.props;

		return (
			<div className={clsx("searchable-dropdown", containerClass)}>
				{title && <span className="searchable-dropdown__title">{title}</span>}

				<input
					ref={ref => (this.inputComponentRef = ref)}
					className={clsx("searchable-dropdown__input", className)}
					type="text"
					name="search"
					onKeyDown={this.onKeyDown}
					onChange={this.onSearchChange}
					onFocus={this.onInputFocus}
					onBlur={this.onInputBlur}
					placeholder={searchPlaceHolder}
					value={search}
					autoComplete="off"
				/>
				{(show || alwaysShow) && results.length > 0 && (
					<div ref={ref => (this.searchListRef = ref)} className="searchable-dropdown__options" style={searchListStyles}>
						{results.map((item, index) => {
							let styles = {};
							let classes = ["searchable-dropdown__options__item"];

							if (item.color) {
								styles.backgroundColor = item.color;
							}

							if (hoverIndex === index && !isListHover) {
								classes.push("searchable-dropdown__options__item--hover");
							}

							classes = classes.join(" ");

							return (
								<div
									ref={ref => this.createIndexReference(index, ref)}
									style={styles}
									className={classes}
									key={item.id}
									onMouseEnter={() => this.onMouseEnter(index)}
									onMouseLeave={() => this.onMouseLeave(index)}
									onMouseDown={() => {
										this.onSelect({ item });
									}}
								>
									{item.name}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default SearchableDropdown;
