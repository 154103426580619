import React from "react";
import * as Icon from "react-feather";
import moment from "moment";

import EventService from "../../services/EventService";
import WComponent from "../common/WComponent";
import PageHeading from "../common/page_heading/PageHeading";

class Events extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			events: []
		};

		this.noteModal = null;
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let newProps = this.props;
		let oldProps = prevProps;

		if (oldProps.engagementId !== newProps.engagementId || oldProps.clientId !== newProps.clientId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { engagementId, clientId } = this.props;

		await this.update({
			engagementId: engagementId || 0,
			clientId: clientId || 0,
			loading: true
		});

		await this.fetchEvents();
		await this.update({ loading: false });
	};

	fetchEvents = async () => {
		let { engagementId, clientId } = this.state;
		let events = await EventService.fetchEvents({ clientId, engagementId });

		await this.update({
			events
		});
	};

	render() {
		let { loading, events } = this.state;

		return (
			<div className="container">
				<PageHeading section="Workflow" title="Events" />

				{loading && <div className="container-loader">Loading ...</div>}

				{events.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no events ...</div>
					</div>
				)}

				{events.length > 0 && !loading && (
					<div className="t">
						<div className="t__header">
							<div className="list-column list-column--xxs">Type</div>
							<div className="list-column list-column--l">Description</div>
							<div className="list-column list-column--xxs">Author</div>
							<div className="list-column list-column--xxs">Date & Time</div>
						</div>

						{events.map((event, index) => {
							let odd = index % 2 === 0;
							return (
								<div className={`list-item list-item--height ${odd ? "list-item--stripe" : ""}`} key={index}>
									<div className="list-column list-column--xxs">{event.type}</div>
									<div className="list-column list-column--l">
										{event.description.split("\n").map(s => (
											<>
												{s}
												<br />
											</>
										))}
									</div>
									<div className="list-column list-column--xxs">{event.User.first_name}</div>
									<div className="list-column list-column--xxs">{moment(event.created_at).format("MMMM Do YYYY @ h:mma")}</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default Events;
