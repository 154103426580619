import UserService from "./UserService";
import Kichiri from "./KichiriService";

const NoteService = {
	/**
	 * Fetch list of notes
	 * @param {Number} clientId
	 * @param {Number} engagementId
	 * @param {Boolean} isPinned
	 * @returns {Array}
	 */
	async fetchNotes({ clientId = null, engagementId = null, isPinned }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.note.list(
				{},
				{
					clientId,
					engagementId,
					isPinned
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a note
	 * @param {Number} noteId
	 * @returns {Object}
	 */
	async fetchNote({ noteId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.note.get({ noteId }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates a note
	 * @param {object} params
	 * @param {number} params.noteId
	 * @param {string | undefined} params.content
	 * @param {boolean | undefined} params.isPinned
	 * @param {"active" | "inactive" | "deleted" | undefined} params.status
	 * @returns {Promise<object | null>}
	 */
	async updateNote({ noteId, content, isPinned, status }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.note.update({ noteId, content, isPinned, status }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a note
	 * @param {String} content
	 * @param {Number} engagementId
	 * @param {Number} clientId
	 * @param {Boolean} isPinned
	 * @returns {Object}
	 */
	async createNote({ content, engagementId, clientId, isPinned }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.note.create({ content, clientId, engagementId, isPinned }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default NoteService;
