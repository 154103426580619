import React from "react";

import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";
import * as Icon from "react-feather";

import { DEFAULT_RELATIVE_DUE_DATE } from "../../../constants/EngagementTemplates";
import EngagementTaskModal from "../EngagementTemplates/eng_task_modal/EngagementTaskModal";

import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";
import ClientService from "../../../services/ClientService";
import WComponent from "../../common/WComponent";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import Tabs from "../../common/tabs/Tabs";
import Tab from "../../common/tabs/tab/Tab";
import RRuleEditor from "../../common/RRule/RRuleEditor";
import EngagementTaskCard from "../../common/eng_task_card/EngagementTaskCard";
import RelativeDate from "../../common/RelativeDate/RelativeDate";
import DateSelector from "../../common/date_selector/DateSelector";
import Button from "../../common/button/Button";

import "./engagement-modal.css";

const TABS = {
	tasks: "tasks",
	users: "users",
	repeats: "repeats"
};

class EngagementModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			show: false,

			teamId: null,
			flowId: null,
			flowStateId: null,
			clientId: 0,
			name: "",
			dateIn: moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
			dueDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
			fiscalYearEnd: new Date(),
			notes: "",

			// Eng Task Data
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,
			showEngagementTaskModal: false,

			flows: [],
			flowStates: [],

			clientName: "",
			selectedTeamName: "",
			selectedTab: "tasks",
			selectedFlowStateName: "",
			tasks: [],
			assignedUsers: [],
			roles: [],

			rrule: "",
			relativeDueDate: DEFAULT_RELATIVE_DUE_DATE
		};

		this.clientSearchableDropdown = null;
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show, flowId } = this.props;

		if (prevProps.show !== show || prevProps.flowId !== flowId) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, clientId, flowId } = this.props;

		await this.update({ loading: true });

		await this.update({
			show,
			flowId,
			clientId: clientId || 0,
			clientName: ""
		});

		await this.setFlow();

		if (clientId) {
			let client = await ClientService.fetchClient({ clientId });

			await this.update({
				clientName: client.client.name
			});
		}

		await this.fetchAllRoles();
		await this.update({ loading: false });
	};

	fetchAllRoles = async () => {
		let roles = await UserService.fetchRoles({});

		await this.update({
			roles: roles
		});
	};

	onDateInSelect = date => {
		this.update({
			dateIn: date
		});
	};

	onDueDateSelect = date => {
		this.update({
			dueDate: date
		});
	};

	onFiscalYearEndSelect = async date => {
		await this.update({
			fiscalYearEnd: date
		});

		this.setName();
		this.setDueDate();
	};

	setFlow = async ({ flow } = {}) => {
		let { flowId } = this.state;

		if (!flow) {
			flow = await EngagementService.fetchFlow({ flowId });
		}

		await this.update({
			flowId: flow.id,
			flowCode: flow.code,
			flowName: flow.name,

			flowStateId: null,
			selectedFlowStateName: ""
		});
	};

	onFlowSelect = async flow => {
		let { flowId } = this.state;

		// User selected same flow, dipset
		if (flowId === flow.id) {
			return;
		}

		await this.setFlow({ flow });

		this.setName();
		this.setDueDate();
	};

	onFlowStateSelect = async flowState => {
		await this.update({
			flowStateId: flowState.id
		});
	};

	setDueDate = () => {
		let { flowCode, fiscalYearEnd } = this.state;
		let dueDate = EngagementService.deriveDueDateForEngagement({ flowCode, fiscalYearEnd });
		this.update({
			dueDate
		});
	};

	setName() {
		let { flowName, clientName, fiscalYearEnd } = this.state;

		let newName = `${flowName} - ${clientName} - ${moment(fiscalYearEnd).format("YYYY-MM-DD")}`;

		this.update({
			name: newName
		});
	}

	onTabSelect = async selectedTab => {
		await this.update({ selectedTab: selectedTab.id });
	};

	clearModal = async () => {
		await this.update({
			name: "",

			teamId: 0,
			clientId: 0,
			// flowId: null,
			flowStateId: null,

			fiscalYearEnd: new Date(),
			dateIn: new Date(),
			dueDate: new Date(),

			assignedUsers: [],

			selectedTeamName: "",
			selectedFlowStateName: "",
			tasks: []
		});

		this.clientSearchableDropdown.clear();
	};

	createEngagement = async () => {
		let { name, teamId, clientId, flowId, flowStateId, dateIn, dueDate, fiscalYearEnd, assignedUsers, tasks, rrule, relativeDueDate, loading } = this.state;

		let created = false;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return created;
		}

		if (loading) {
			alert.info("Updating...");
			return;
		}

		await this.update({ loading: true });

		try {
			let { data } = await EngagementService.create({
				name,

				teamId,
				clientId,
				flowId,
				flowStateId,

				dueDate: moment(dueDate).format("YYYY-MM-DD"),
				dateIn: moment(dateIn).format("YYYY-MM-DD"),
				fiscalYearEnd: moment(fiscalYearEnd).format("YYYY-MM-DD"),

				assignedUsers,
				tasks,

				rrule,
				relativeDueDate
			});

			this.clearModal();

			created = data.id;
		} catch (error) {
			console.log(error);

			created = null;
		}

		await this.update({ loading: false });

		return created;
	};

	onCreate = async () => {
		let created = await this.createEngagement();

		if (created && this.props.onCreate) {
			this.update({
				show: false
			});

			this.props.onCreate(created);
		}
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}

		this.clearModal();
	};

	onClientSelect = async item => {
		await this.update({
			clientId: item.id,
			clientName: item.name
		});

		this.setName();
	};

	isDisabled = () => {
		let { loading } = this.state;
		return !this.isValid() || loading;
	};

	isValid = () => {
		let { name, clientId, flowId, flowStateId, dateIn, dueDate, fiscalYearEnd } = this.state;
		if (!flowId) {
			return false;
		}

		console.log({ name, fiscalYearEnd, clientId, flowId, flowStateId, dateIn, dueDate });

		return name.length > 0 && fiscalYearEnd !== null && clientId !== 0 && flowId !== 0 && flowStateId !== 0 && dateIn && dueDate;
	};

	onChange = e => {
		let { name, value } = e.target;

		this.update({
			[name]: value
		});
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		let newAssignedUsers = selectedUsers.slice();

		await this.update({
			assignedUsers: newAssignedUsers
		});
	};

	onSelectTeam = async team => {
		await this.update({
			teamId: team.id,
			selectedTeamName: team.name
		});
	};

	getFlowStatusName = async ({ flowId, flowStateId }) => {
		let flowStatusName = "";
		let flowStates = await EngagementService.fetchFlowStates({ flowId });

		// loop through the flow state and get the flow state name that matches the flow state id
		for (let i = 0; i < flowStates.length; i++) {
			if (flowStates[i].id === flowStateId) {
				flowStatusName = flowStates[i].name;
			}
		}

		return flowStatusName;
	};

	findRole = idToQuery => {
		let { roles } = this.state;

		const result = roles.find(item => item.id === idToQuery);
		let name = "";

		if (result) {
			name = result.name;
		} else {
			console.log("Object not found with ID:", idToQuery);
		}

		return name;
	};

	onSelectTemplate = async template => {
		let team = await UserService.fetchTeam({ teamId: template.team_id });

		await this.update({
			flowId: template.flow_id
		});

		await this.setFlow();

		await this.update({
			teamId: template.team_id,
			selectedTeamName: team.name || "",

			flowStateId: template.flow_state_id,
			selectedFlowStateName: await this.getFlowStatusName({ flowId: template.flow_id, flowStateId: template.flow_state_id }),

			assignedUsers: team.users,
			tasks: JSON.parse(template.tasks)
		});

		await this.setName();
		await this.setDueDate();
	};

	onRRuleChange = async ({ rrule }) => {
		await this.update({ rrule });
	};

	onNewEngTask = async () => {
		await this.update({
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,
			showEngagementTaskModal: true
		});
	};

	onUpdateEngTask = async index => {
		await this.update({
			engagementTaskAction: "update",
			engTaskUpdateIndex: index,
			showEngagementTaskModal: true
		});
	};

	onRemoveTask = index => {
		let { tasks } = this.state;
		let prevTasks = tasks;
		prevTasks.splice(index, 1);

		this.update({
			tasks: prevTasks
		});
	};

	onRelativeDueDateChange = async relativeDueDate => {
		await this.update({ relativeDueDate });
	};

	render() {
		let {
			// View Toggles
			show,

			// Engagement Data
			name,
			fiscalYearEnd,
			dateIn,
			dueDate,

			tasks,
			assignedUsers,

			rrule,
			relativeDueDate,

			// Eng Task Data
			showEngagementTaskModal,
			engagementTaskAction,
			engTaskUpdateIndex,

			// Display Items & Dirty Bits
			clientName,
			flowName,
			flowCode,
			selectedTeamName,
			selectedFlowStateName,
			selectedTemplate,
			selectedTab
		} = this.state;

		return (
			<Modal open={show} onClose={this.onClose} center>
				<EngagementTaskModal
					show={showEngagementTaskModal}
					action={engagementTaskAction}
					onClose={() => {
						this.update({
							engagementTaskAction: "new",
							engTaskUpdateIndex: null,
							showEngagementTaskModal: false
						});
					}}
					engTasks={tasks}
					taskToUpdateIndex={engTaskUpdateIndex}
				/>

				<div className="eng-modal">
					<div className="modal-title">New Engagement</div>
					<div className="eng-modal__body">
						<div className="eng-modal__body__fields">
							<div className="eng-modal__body__fields__container">
								<SearchableDropdown
									title="Template"
									onSelect={this.onSelectTemplate}
									type={"engTemplate"}
									selectedName={selectedTemplate}
									showAll
								/>

								<TextFieldInput title="Name (Will Auto Populate)" name="name" onChange={this.onChange} autoComplete="off" value={name} />

								<SearchableDropdown
									title="Client"
									ref={ref => (this.clientSearchableDropdown = ref)}
									selectedName={clientName}
									onSelect={this.onClientSelect}
									type={"clients"}
								/>
								<SearchableDropdown title="Team" onSelect={this.onSelectTeam} type={"teams"} selectedName={selectedTeamName} showAll />
							</div>

							<div className="eng-modal__body__fields__container">
								<SearchableDropdown showAll title="Workflow Type" onSelect={this.onFlowSelect} selectedName={flowName} type={"flows"} />

								<SearchableDropdown
									showAll
									title="Status"
									type="flowStates"
									flowCode={flowCode}
									selectedName={selectedFlowStateName}
									onSelect={this.onFlowStateSelect}
								/>

								<DateSelector
									// TODO: this classname/component will be refactored in the final theme fixes pr
									containerClass="eng-modal__date-selector"
									title="Fiscal Year End"
									selectedDate={new Date(fiscalYearEnd)}
									onDateSelect={this.onFiscalYearEndSelect}
								/>

								<DateSelector
									containerClass="eng-modal__date-selector"
									title="Date In"
									selectedDate={dateIn !== "" ? new Date(dateIn) : new Date()}
									onDateSelect={this.onDateInSelect}
								/>

								<DateSelector
									title="Due Date"
									selectedDate={dueDate !== "" ? new Date(dueDate) : new Date()}
									onDateSelect={this.onDueDateSelect}
								/>
							</div>
						</div>

						<div className="eng-modal__body__settings">
							<Tabs className="eng-modal__body__settings__tabs" onSelect={this.onTabSelect} selected={selectedTab}>
								<Tab id={TABS.tasks} text="Tasks" />
								<Tab id={TABS.users} text="Users" />
								<Tab id={TABS.repeats} text="Repeat Settings" />
							</Tabs>

							{selectedTab === TABS.tasks && (
								<div className="eng-modal__body__settings__tasks">
									<div className="eng-modal__body__settings__tasks__list">
										{tasks.map((task, index) => (
											<EngagementTaskCard
												key={index}
												task={task}
												index={index}
												onRemoveTask={this.onRemoveTask}
												onUpdateEngTask={this.onUpdateEngTask}
											/>
										))}
									</div>

									<div className="eng-modal__body__settings__tasks__cta">
										<div className="eng-modal__body__settings__tasks__cta__title">click the plus button to add a Task</div>
										<Icon.PlusCircle
											className="eng-modal__body__settings__tasks__cta__button"
											size={28}
											color="#000"
											onClick={this.onNewEngTask}
										/>
									</div>
								</div>
							)}

							{selectedTab === TABS.users && (
								<UserAssigner containerClass="eng-modal__body__user-assigner" onChange={this.onAssignedUsersChanged} users={assignedUsers} />
							)}
							{selectedTab === TABS.repeats && (
								<>
									<RRuleEditor onChange={this.onRRuleChange} rrule={rrule} />
									{rrule.length > 0 && (
										<>
											<div className="eng-modal__body__settings__divider" />
											<div className="eng-modal__body__settings__repeats__section">
												<RelativeDate title="Recurring Due Date" onChange={this.onRelativeDueDateChange} data={relativeDueDate} />
											</div>
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<Button view="secondary" text="Cancel" onClick={this.onClose} />
					<Button text="Create" onClick={this.onCreate} disabled={this.isDisabled()} />
				</div>
			</Modal>
		);
	}
}

export default EngagementModal;
