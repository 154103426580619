import React from "react";

// Libraries
import moment from "moment";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";
import Board from "react-trello";
import { toast as alert } from "react-toastify";

// Services
import EngagementService from "../../../services/EngagementService";
import UserService from "../../../services/UserService";
import DeleteConfirmationModal from "../../common/modal/confirmation_modal/ConfirmationModal";

// Components
import WComponent from "../../common/WComponent";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import EngagementTaskModal from "./eng_task_modal/EngagementTaskModal";
import EngagementTaskCard from "../../common/eng_task_card/EngagementTaskCard";
import Button from "../../common/button/Button";

// Styling
import "./engagement-template-modal.css";

class EngagementTemplateModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			engagementTemplateId: "new",
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,

			deletingEngagementTemplate: false,

			teamId: null,
			flowId: null,
			flowStateId: null,
			name: "",
			notes: "",
			fiscalYearEnd: new Date(),

			loading: false,
			show: false,
			showEngagementTaskModal: false,

			selectedTeamName: "",
			selectedFlowName: "",
			selectedFlowStateName: "",

			taskId: "new",
			filter: "active",
			flows: [],

			flowCode: null,
			roles: [],
			tasks: [],

			status: "active",
			showDeleteConfirmation: false,
			isDragging: false
		};

		this.taskTemplatesModal = null;
		this.onNewEngTask = this.onNewEngTask.bind(this);
		this.fetchAllRoles = this.fetchAllRoles.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		if (!this.props) {
			return;
		}
		let { show, engagementTemplateId } = this.props;
		if (prevProps.engagementTemplateId !== engagementTemplateId || prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { engagementTemplateId, show, name, selectedFlow, selectedTeam, tasks } = this.props;
		let { deletingEngagementTemplate } = this.state;

		this.clearModal();

		await this.update({
			show,
			engagementTemplateId,
			loading: true,
			showDeleteConfirmation: false
		});

		if (deletingEngagementTemplate) {
			await this.update({ deletingEngagementTemplate: false });
			return;
		}

		await this.fetchAllRoles();

		// if updating a engagement template
		if (engagementTemplateId !== "new") {
			const engTemplate = await EngagementService.fetchEngagementTemplate({ engagementTemplateId });
			const flowMetadata = await this.getFlowMetadata({ engTemplate });
			const flowStatusName = await this.getFlowStatusName({ engTemplate, flowCode: flowMetadata.code });
			const team = await this.fetchTeam({ teamId: engTemplate.team_id });

			await this.update({
				name: engTemplate.name,
				teamId: engTemplate.team_id,
				selectedTeamName: team.name,
				flowId: engTemplate.flow_id,
				flowStateId: engTemplate.flow_state_id,
				selectedFlowName: flowMetadata.name,
				selectedFlowStateName: flowStatusName,
				tasks: this.parseAndSortTasks({ tasks: engTemplate.tasks }),
				status: engTemplate.status,
				flowCode: flowMetadata.code
			});
		} else if (engagementTemplateId === "new") {
			let { flowType, flowState } = selectedFlow || { flowType: {}, flowState: {} };
			let team = selectedTeam || {};

			await this.update({
				name: name,
				flowId: flowType.id || 0,
				selectedFlowName: flowType.name || "",
				flowStateId: flowState.id || 0,
				selectedFlowStateName: flowState.name || "",
				teamId: team.id || 0,
				selectedTeamName: team.name || "",
				tasks: tasks || [],
				flowCode: flowType.flowCode
			});
		}

		await this.update({ loading: false });
	};

	getFlowMetadata = async ({ engTemplate }) => {
		let flow = await EngagementService.fetchFlow({ flowId: engTemplate.flow_id });

		return flow;
	};

	getFlowStatusName = async ({ engTemplate, flowCode }) => {
		let flowStatusName = "";
		let flowStates = await EngagementService.fetchFlowStates({ flowCode, flowId: engTemplate.flow_id });

		// loop through the flow state and get the flow state name that matches the flow state id
		for (let i = 0; i < flowStates.length; i++) {
			if (flowStates[i].id === engTemplate.flow_state_id) {
				flowStatusName = flowStates[i].name;
			}
		}

		return flowStatusName;
	};

	onNewEngTask = async () => {
		await this.update({
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,
			showEngagementTaskModal: true
		});
	};

	onUpdateEngTask = async index => {
		await this.update({
			engagementTaskAction: "update",
			engTaskUpdateIndex: index,
			showEngagementTaskModal: true
		});
	};

	fetchAllRoles = async () => {
		let roles = await UserService.fetchRoles({});

		await this.update({
			roles: roles
		});
	};

	show = () => {
		this.update({
			show: true
		});
	};

	onDateInSelect = date => {
		this.update({
			dateIn: date
		});
	};

	onFiscalYearEndSelect = async date => {
		await this.update({
			fiscalYearEnd: date
		});
	};

	onFlowSelect = async flow => {
		let { flowId } = this.state;

		// User selected same flow, dipset
		if (flowId === flow.id) {
			return;
		}

		await this.update({
			flowId: flow.id,
			flowCode: flow.code,
			selectedFlowName: flow.name,

			flowStateId: null,
			selectedFlowStateName: ""
		});
	};

	onFlowStateSelect = async flowState => {
		await this.update({
			flowStateId: flowState.id
		});
	};

	setName() {
		let { selectedFlowName, clientName, fiscalYearEnd } = this.state;

		let newName = `${selectedFlowName} - ${clientName} - ${moment(fiscalYearEnd).format("YYYY-MM-DD")}`;

		this.update({
			name: newName
		});
	}

	clearModal = async () => {
		await this.update({
			name: "",
			tasks: [],
			teamId: 0,
			clientId: 0,
			flowId: null,
			flowStateId: null,

			fiscalYearEnd: new Date(),
			dateIn: new Date(),

			selectedTeamName: "",
			selectedFlowName: "",
			selectedFlowStateName: ""
		});
	};

	createEngagementTemplate = async () => {
		let { name, teamId, flowId, flowStateId, fiscalYearEnd, tasks } = this.state;

		let created = false;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return created;
		}

		if (this.state.loading) {
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			let data = await EngagementService.createEngagementTemplate({
				name,
				teamId,
				flowId,
				flowStateId,
				fiscalYearEnd: moment(fiscalYearEnd).format("YYYY-MM-DD"),
				users: JSON.stringify([]),
				tasks: JSON.stringify(tasks)
			});

			this.clearModal();

			created = data.id;
		} catch (error) {
			console.log(error);

			created = null;
		}

		this.update({
			loading: false
		});

		return created;
	};

	updateEngagementTemplate = async () => {
		let { name, teamId, flowId, flowStateId, fiscalYearEnd, tasks, engagementTemplateId, status } = this.state;

		let created = false;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return created;
		}

		if (this.state.loading) {
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			let data = await EngagementService.updateEngagementTemplate({
				engagementTemplateId,
				name,
				teamId,
				flowId,
				flowStateId,
				fiscalYearEnd: moment(fiscalYearEnd).format("YYYY-MM-DD"),
				users: JSON.stringify([]),
				tasks: JSON.stringify(tasks),
				status
			});

			this.clearModal();

			created = data.id;
		} catch (error) {
			console.log(error);

			created = null;
		}

		this.update({
			loading: false
		});

		return created;
	};

	isNewEngagementTemplate = () => {
		let { engagementTemplateId } = this.state;
		return engagementTemplateId === "new";
	};

	onCreate = async () => {
		let created = await this.createEngagementTemplate();

		if (created && this.props.onCreate) {
			this.onCreateOrUpdate(created);
		}
	};

	onUpdate = async () => {
		let created = await this.updateEngagementTemplate();

		if (created && this.props.onCreate) {
			this.onCreateOrUpdate(created);
		}
	};

	onCreateOrUpdate(created) {
		this.update({
			task: []
		});

		if (this.props.onCreate) {
			this.props.onCreate(created);
		}

		this.props.onClose(created);
	}

	onClose = async created => {
		if (this.props.onClose) {
			// clear tasks
			this.update({
				tasks: []
			});

			this.props.onClose();
		}
	};

	onDelete = async () => {
		await this.update({
			showDeleteConfirmation: true
		});
	};

	onConfirmDeleteEngagementTemplate = async ({ confirmed }) => {
		await this.update({ showDeleteConfirmation: false });
		if (!confirmed) {
			return;
		}

		try {
			await this.update({ status: "deleted", deletingEngagementTemplate: true });
			this.onUpdate();
			alert.success("Engagement Template Deleted!");
		} catch (error) {
			console.log(error);
		}
	};

	isDisabled = () => {
		let { loading } = this.state;
		return !this.isValid() || loading;
	};

	isValid = () => {
		let {
			name,
			teamId,
			flowId,
			flowStateId
			/**
			 * fiscalYearEnd #commented out as it is not in use W-425
			 * **/
		} = this.state;

		if (!name) {
			return false;
		}
		if (!teamId) {
			return false;
		}
		if (!flowId) {
			return false;
		}
		if (!flowStateId) {
			return false;
		}
		return true;
	};

	onChange = e => {
		let { name, value } = e.target;

		this.update({
			[name]: value
		});
	};

	onSelectTeam = async team => {
		await this.update({
			teamId: team.id,
			selectedTeamName: team.name
		});
	};

	onRemoveTask = index => {
		let { tasks } = this.state;
		let prevTasks = tasks;
		prevTasks.splice(index, 1);

		this.update({
			tasks: prevTasks
		});
	};

	findRole = idToQuery => {
		let { roles } = this.state;

		const result = roles.find(item => item.id === idToQuery);
		let name = "";

		if (result) {
			name = result.name;
		} else {
			console.log("Object not found with ID:", idToQuery);
		}

		return name;
	};

	fetchTeam = async ({ teamId }) => {
		let team = await UserService.fetchTeam({ teamId });
		if (!team) {
			return {};
		}

		return team;
	};

	isNewTask = () => {
		let { engagementTemplateId } = this.state;
		return engagementTemplateId === "new";
	};

	assignIndexToTasks(tasks) {
		return tasks.map((task, idx) => {
			if (typeof task.index === "undefined" || task.index === null) {
				return { ...task, index: idx };
			}
			return task;
		});
	}

	parseAndSortTasks({ tasks }) {
		if (!tasks) {
			return [];
		}
		let engTasks = JSON.parse(tasks);
		const tasksWithIndex = this.assignIndexToTasks(engTasks);
		const sortedTasks = tasksWithIndex.sort((a, b) => a.index - b.index);
		return sortedTasks;
	}

	handleCardMove = (fromLaneId, toLaneId, cardId, newIndex) => {
		const { tasks } = this.state;
		const oldIndex = parseInt(cardId, 10);
		const [movedTask] = tasks.splice(oldIndex, 1);

		tasks.splice(newIndex, 0, movedTask);
		const updatedTasks = tasks.map((task, index) => ({ ...task, index }));
		this.update({ tasks: updatedTasks });
	};

	handleDragStart = () => {
		this.update({ isDragging: true });
	};

	handleDragEnd = () => {
		this.update({ isDragging: false });
	};

	render() {
		let {
			// View Toggles
			show,
			showEngagementTaskModal,

			// Engagement Data
			name,
			// fiscalYearEnd, // TODO # W-345 - commented out as it is not used

			// Display Items & Dirty Bits
			engagementTaskAction,
			selectedTeamName,
			selectedFlowName,
			selectedFlowStateName,
			flowCode,

			engTaskUpdateIndex,
			tasks,
			showDeleteConfirmation
		} = this.state;

		let {
			isNewTask,
			onChange,
			onRemoveTask,
			onUpdateEngTask,
			onNewEngTask,
			onClose,
			onDelete,
			onCreate,
			onUpdate,
			onConfirmDeleteEngagementTemplate,
			onSelectTeam,
			onFlowSelect,
			onFlowStateSelect,
			handleCardMove,
			handleDragEnd,
			handleDragStart
		} = this;

		const lane = {
			id: "taskLane",
			cards: tasks.map((task, index) => ({
				id: index.toString(),
				task,
				index,
				onRemoveTask,
				onUpdateEngTask
			}))
		};

		const modalAnimationClass = this.state.isDragging ? "modal__no-animation" : "modal__animation";

		return (
			<>
				<Modal
					open={show}
					onClose={onClose}
					centern
					classNames={{
						modalAnimationIn: modalAnimationClass
					}}
				>
					<EngagementTaskModal
						show={showEngagementTaskModal}
						action={engagementTaskAction}
						onClose={() => {
							// hide modal
							this.update({
								engagementTaskAction: "new",
								engTaskUpdateIndex: null,
								showEngagementTaskModal: false
							});
						}}
						engTasks={tasks}
						taskToUpdateIndex={engTaskUpdateIndex}
					/>

					<div className="etm">
						<div className="modal-title">{isNewTask() ? "New" : "Update"} Engagement Template</div>

						<div className="etm__fields">
							<div className="etm__fields__inputs">
								<TextFieldInput title="Name" name="name" onChange={onChange} autoComplete="off" value={name} />

								<SearchableDropdown title="Team" onSelect={onSelectTeam} type={"teams"} selectedName={selectedTeamName} showAll />
							</div>

							<div className="etm__fields__inputs">
								<SearchableDropdown showAll title="Workflow Type" onSelect={onFlowSelect} selectedName={selectedFlowName} type={"flows"} />

								<SearchableDropdown
									showAll
									title="Status"
									type="flowStates"
									flowCode={flowCode}
									selectedName={selectedFlowStateName}
									onSelect={onFlowStateSelect}
								/>
							</div>
						</div>

						<div className="etm__tasks">
							<div className="etm__tasks__task-list">
								<Board
									data={{ lanes: [lane] }}
									components={{
										Card: EngagementTaskCard
									}}
									style={{ backgroundColor: "#eaedf3", overflowX: "scroll", gap: 20 }}
									laneStyle={{
										backgroundColor: "transparent"
									}}
									draggable={false}
									onCardMoveWithinLane={handleCardMove}
									onCardMoveAcrossLanes={handleCardMove}
									handleDragStart={handleDragStart}
									handleDragEnd={handleDragEnd}
								/>
							</div>

							<div className="etm__tasks__cta">
								<div className="etm__tasks__cta__title">click the plus button to add a Task</div>
								<Icon.PlusCircle className="etm__tasks__cta__button" size={28} color="#385395" onClick={onNewEngTask} />
							</div>
						</div>
					</div>
					<div className="modal-footer">
						{!isNewTask() && <Button view="secondary" onClick={onDelete} icon={<Icon.Trash2 size={22} />} />}
						<Button view="secondary" text="Cancel" onClick={onClose} />
						<Button
							onClick={() => {
								if (isNewTask()) {
									onCreate();
								} else {
									onUpdate();
								}
							}}
							text={isNewTask() ? "Create" : "Update"}
						/>
					</div>
				</Modal>
				<DeleteConfirmationModal
					title="Delete Engagement Template?"
					warning="Are you sure you want to delete this engagement template?"
					show={showDeleteConfirmation}
					onClose={onConfirmDeleteEngagementTemplate}
				/>
			</>
		);
	}
}

export default EngagementTemplateModal;
