// External Libraries
import React from "react";
import DatePicker from "react-datepicker";

// Services
import PlaidService from "../../../../services/PlaidService";

// Common Components
import WComponent from "../../../common/WComponent";
import Loader from "../../../common/Loader";
import SearchableDropdown from "../../../common/searchable_dropdown/SearchableDropdown";
import DateSelector from "../../../common/date_selector/DateSelector";
import Button from "../../../common/button/Button";
import PageHeading from "../../../common/page_heading/PageHeading";

// Styling
import "./transactions-tool.css";

class TransactionsTool extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			startDate: new Date(),
			endDate: new Date(),
			plaidAccountId: null,

			// Data State
			transactions: [],

			// UI State
			loading: false
		};
	}

	componentDidMount() {}

	onPlaidAccountSelect = account => {
		this.update({
			plaidAccountId: account.id
		});
	};

	onStartDateSelect = date => {
		this.update({
			startDate: date
		});
	};

	onEndDateSelect = date => {
		this.update({
			endDate: date
		});
	};

	onGetRecords = async () => {
		let { clientId } = this.props;
		let { startDate, endDate, plaidAccountId, loading } = this.state;

		if (loading) {
			return;
		}

		this.update({
			loading: true
		});

		let transactions = await PlaidService.fetchTransactions({
			clientId,
			startDate,
			endDate,
			plaidAccountId,
			searchTerm: ""
		});

		// TODO - Rendering Transactions

		await this.update({
			transactions,
			loading: false
		});
	};

	render() {
		let { clientId } = this.props;
		let { transactions, startDate, endDate, loading } = this.state;

		return (
			<div className="transactions-tool">
				<PageHeading className="transactions-tool__heading" title="Bank Records" />

				<SearchableDropdown
					title="Select a Bank Account ..."
					type={"plaidAccounts"}
					onSelect={this.onPlaidAccountSelect}
					clientId={clientId}
					firstFocus
					showAll
				/>

				<DateSelector title="Start Date" selectedDate={startDate !== "" ? new Date(startDate) : new Date()} onDateSelect={this.onStartDateSelect} />

				<DateSelector title="End Date" selectedDate={endDate !== "" ? new Date(endDate) : new Date()} onDateSelect={this.onEndDateSelect} />

				<Button text="Get Records" onClick={this.onGetRecords} />

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}
			</div>
		);
	}
}

export default TransactionsTool;
