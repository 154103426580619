import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";
import clsx from "clsx";

import WORKFLOW from "../../../constants/Workflow";

import WComponent from "../../common/WComponent";
import DateRangeFilter from "./DateRangeFilter";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import ClientAssigner from "../../common/client_assigner/ClientAssigner";
import ClientGroupAssigner from "../../common/client_group_assigner/ClientGroupAssigner";
import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";
import Button from "../../common/button/Button";
import DateSelector from "../../common/date_selector/DateSelector";

import "./engagement-filter-modal.css";

export default class EngagementFilterModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			menuItems: ["Dates", "Users", "Teams", "Clients", "Client Groups", "Status", "Columns", "Last Activity"],
			selectedMenuItem: "Dates",

			assignedUsers: [],
			assignedTeams: [],
			assignedClients: [],
			assignedClientGroups: [],
			yearEnd: WORKFLOW.defaultDateFunction,
			dueDate: WORKFLOW.defaultDateFunction,
			dateIn: WORKFLOW.defaultDateFunction,

			selectedColumns: {},

			selectedFlow: {},
			selectedFlowStates: [],
			lastActivityDate: {}
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show, filters } = this.props;

		let filtersChanges = JSON.stringify(prevProps.filters) !== JSON.stringify(filters);

		if (prevProps.show !== show || filtersChanges) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, filters } = this.props;

		let {
			assignedUsers,
			assignedTeams,
			assignedClients,
			assignedClientGroups,
			selectedFlowStates,
			yearEnd,
			dueDate,
			dateIn,
			columns,
			lastActivityDate
		} = filters;

		//overriding selectedFlowStates with updated GroupIndex
		selectedFlowStates = this.initializeFlowStates(selectedFlowStates);

		let users = await UserService.fetchUsers({});

		let extractedFlow = this.grabSingleFlowFromStates({ selectedFlowStates });
		let flow = null;

		if (extractedFlow && extractedFlow.flowId) {
			flow = await EngagementService.fetchFlow({ flowId: extractedFlow.flowId });
		}

		// state is reset to whichever state is passed from filters
		await this.update({
			show,
			users,
			selectedFlow: flow,
			selectedFlowStates: selectedFlowStates,

			assignedUsers,
			assignedTeams: assignedTeams || [],
			assignedClients: assignedClients || [],
			assignedClientGroups: assignedClientGroups || [],
			yearEnd,
			dueDate,
			dateIn,
			selectedColumns: columns || {},
			lastActivityDate
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		await this.update({
			assignedUsers: selectedUsers
		});

		// if (this.props.onFilterChange) {
		// 	this.props.onFilterChange({ filters: { assignedUsers } });
		// }
	};

	grabSingleFlowFromStates = ({ selectedFlowStates }) => {
		if (!selectedFlowStates) {
			return null;
		}

		const firstFlow = selectedFlowStates[0];
		if (!firstFlow) {
			return null;
		}
		const firstFlowId = selectedFlowStates[0].flowId;

		const singleFlowSelected = selectedFlowStates.every(flowState => flowState.flowId === firstFlowId);

		return singleFlowSelected
			? {
					flowId: firstFlow.flowId,
					name: firstFlow.flowName,
					code: firstFlow.code
			  }
			: null;
	};

	onApply = () => {
		let {
			assignedUsers,
			assignedTeams,
			assignedClients,
			assignedClientGroups,
			yearEnd,
			dueDate,
			dateIn,
			selectedColumns,
			selectedFlowStates,
			lastActivityDate
		} = this.state;
		const flow = this.grabSingleFlowFromStates({ selectedFlowStates });
		if (this.props.onFilterChange) {
			this.props.onFilterChange({
				filters: {
					assignedUsers,
					assignedTeams,
					assignedClients,
					assignedClientGroups,
					yearEnd,
					dueDate,
					dateIn,
					selectedFlowStates,
					flow,
					lastActivityDate
				},
				columns: selectedColumns
			});
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	getCurrentFlowSelectedFlowStates() {
		let { selectedFlowStates, selectedFlow } = this.state;
		let flowStates = selectedFlowStates.filter(flowState => flowState.flowId === selectedFlow.id);
		return flowStates;
	}
	onFlowStateSelect = async flowState => {
		let { selectedFlow } = this.state;

		let flowStates = this.getCurrentFlowSelectedFlowStates();

		let [flowStateExistsInCurrentFlow] = flowStates.filter(flow => flow.flowStateId === flowState.id);

		if (!flowStateExistsInCurrentFlow) {
			const previousFlows = [...this.state.selectedFlowStates];

			const state = {
				flowId: selectedFlow.id,
				flowName: selectedFlow.name,
				flowStateId: flowState.id,
				flowStateName: flowState.name,
				flowStateColor: flowState.color
			};

			previousFlows.push(state);

			await this.update({
				selectedFlowStates: previousFlows
			});
		}
	};

	onFlowStateRemove = async ({ flowStateId }) => {
		let previousFlows = [...this.state.selectedFlowStates];

		previousFlows = previousFlows.filter(flow => flow.flowStateId !== flowStateId);

		await this.update({
			selectedFlowStates: previousFlows
		});
	};

	onFlowRemove = async ({ flowName }) => {
		let previousFlows = [...this.state.selectedFlowStates];

		previousFlows = previousFlows.filter(flow => flow.flowName !== flowName);

		await this.update({
			selectedFlowStates: previousFlows
		});
	};

	getFlowStateFromId({ flowStateId }) {
		let { flow } = this.state;

		let flowStates = flow.flow_states;

		let [flowState] = flowStates.filter(flow => {
			return flow.id === flowStateId;
		});

		return flowState;
	}

	onSelectAllFlowStates = async ({ selectedFlow, selectedFlowStates = [] }) => {
		if (!selectedFlow) {
			return;
		}

		let previousFlows = [...selectedFlowStates];
		previousFlows = previousFlows.filter(flow => flow.flowId !== selectedFlow.id);

		selectedFlow.flow_states.forEach(flowState => {
			previousFlows.push({
				flowId: selectedFlow.id,
				flowName: selectedFlow.name,
				flowStateId: flowState.id,
				flowStateName: flowState.name,
				flowStateColor: flowState.color
			});
		});

		await this.update({
			selectedFlowStates: previousFlows
		});
	};

	onFlowSelect = async flow => {
		await this.update({
			selectedFlow: flow
		});
	};

	renderFlowHeader = (flowName, flowHeaders) => {
		if (flowHeaders.has(flowName)) {
			return null;
		}
		flowHeaders.add(flowName);
		return (
			<div className="assigned-flow-type__item">
				<div>{flowName}</div>

				<Icon.XCircle size={28} color="var(--primary-main)" onClick={() => this.onFlowRemove({ flowName: flowName })} />
			</div>
		);
	};

	initializeFlowStates = selectedFlowStates => {
		let groupCounter = 0;
		const groupIndexMap = {};

		return selectedFlowStates.map(flow => {
			if (!groupIndexMap[flow.flowName]) {
				groupCounter++;
				groupIndexMap[flow.flowName] = groupCounter;
			}

			return {
				...flow,
				groupIndex: groupIndexMap[flow.flowName]
			};
		});
	};

	renderAssignedFlowFilters() {
		const { selectedFlowStates } = this.state;
		const flowHeaders = new Set();

		if (!selectedFlowStates) {
			return null;
		}
		// grouping allows for rerendering of group when new flow and its states are selected
		const groupedFlows = selectedFlowStates.reduce((acc, flow) => {
			acc[flow.flowName] = acc[flow.flowName] || [];
			acc[flow.flowName].push(flow);
			return acc;
		}, {});

		const sortedGroupFlows = Object.keys(groupedFlows)
			.sort((a, b) => {
				return groupedFlows[a][0].groupIndex - groupedFlows[b][0].groupIndex;
			})
			.reduce((acc, key) => {
				acc[key] = groupedFlows[key];
				return acc;
			}, {});

		return Object.entries(sortedGroupFlows).map(([flowName, selectedFlowStates], index) => (
			<div key={`group-${index}`}>
				{this.renderFlowHeader(flowName, flowHeaders)}
				{selectedFlowStates.map((flow, flowIndex) => {
					let flowStateStyles = { backgroundColor: flow.flowStateColor };
					return (
						<div key={`${index}-${flowIndex}-${flow.flowId}-${flow.flowStateId}`}>
							<div className="assigned-flow-state__item" style={flowStateStyles}>
								<div>{flow.flowStateName}</div>

								<Icon.XCircle
									size={28}
									color="var(--primary-main)"
									onClick={() => this.onFlowStateRemove({ flowId: flow.flowId, flowStateId: flow.flowStateId })}
								/>
							</div>
						</div>
					);
				})}
			</div>
		));
	}

	renderFlowStateFilters = () => {
		let { selectedFlow } = this.state;

		return (
			<div>
				<div className="engagement-filter-modal__body__search">
					<SearchableDropdown
						containerClass="engagement-filter-modal__body__search__s-d-container"
						title="Flow"
						type="flows"
						onSelect={this.onFlowSelect}
						showAll
						selectedName={selectedFlow ? selectedFlow.name : ""}
					/>
					<SearchableDropdown
						containerClass="engagement-filter-modal__body__search__s-d-container"
						title="Status"
						onSelect={this.onFlowStateSelect}
						flowCode={selectedFlow ? selectedFlow.code : undefined}
						showAll
						type={"flowStates"}
					/>
					<Button
						className="engagement-filter-modal__body__search__select-all-btn"
						text="Select All"
						view="secondary"
						onClick={() =>
							this.onSelectAllFlowStates({
								selectedFlow: selectedFlow,
								selectedFlowStates: this.state.selectedFlowStates
							})
						}
					/>
				</div>

				<div className="assigned-flow-state">
					<div className="assigned-flow-state__title">Current Filters:</div>

					{this.renderAssignedFlowFilters()}
				</div>
			</div>
		);
	};

	onDateInSelect = async ({ dateFunction, payload }) => {
		let newDateIn = {
			dateFunction,
			payload
		};

		await this.update({
			dateIn: newDateIn
		});
	};

	onDueDateSelect = async ({ dateFunction, payload }) => {
		let newDueDate = {
			dateFunction,
			payload
		};

		await this.update({
			dueDate: newDueDate
		});
	};

	onYearEndSelect = async ({ dateFunction, payload }) => {
		let newYearEnd = {
			dateFunction,
			payload
		};

		await this.update({
			yearEnd: newYearEnd
		});
	};

	renderDates = () => {
		let { yearEnd, dueDate, dateIn } = this.state;

		return (
			<>
				<div className="engagement-filter-modal__label">Date In</div>
				<DateRangeFilter dateFunction={dateIn} onSelect={this.onDateInSelect} />

				<div className="engagement-filter-modal__label">Due Date</div>
				<DateRangeFilter dateFunction={dueDate} onSelect={this.onDueDateSelect} />

				<div className="engagement-filter-modal__label">Year End</div>
				<DateRangeFilter dateFunction={yearEnd} onSelect={this.onYearEndSelect} />
			</>
		);
	};

	renderUsers = () => {
		let { assignedUsers } = this.state;

		return <UserAssigner onChange={this.onAssignedUsersChanged} hideRolePicker users={assignedUsers} />;
	};

	onTeamSelect = team => {
		let { assignedTeams } = this.state;
		if (assignedTeams.includes(t => t.id === team.id)) {
			return;
		}

		assignedTeams.push({
			id: team.id,
			name: team.name
		});

		this.update({
			assignedTeams
		});
	};

	onTeamRemove = team => {
		let { assignedTeams } = this.state;
		assignedTeams = assignedTeams.filter(t => t.id !== team.id);

		this.update({
			assignedTeams
		});
	};

	renderTeams = () => {
		let { assignedTeams } = this.state;

		return (
			<>
				<SearchableDropdown title="Team" onSelect={this.onTeamSelect} showAll prependUnassigned type={"teams"} selectedName={""} />
				<div className="assigned-team__title">Current Teams:</div>

				<div>
					{assignedTeams.map(team => (
						<div key={team.name} className="assigned-team__item">
							{team.name}

							<Icon.XCircle className="user-assigner__list__item__icon" size={28} color="#FF6B6B" onClick={() => this.onTeamRemove(team)} />
						</div>
					))}
				</div>
			</>
		);
	};

	onAssignedClientsChanged = async ({ selectedClients }) => {
		await this.update({
			assignedClients: selectedClients
		});
	};

	renderLastActivity = () => {
		const { onLastActivityDateChanged } = this;
		const { lastActivityDate } = this.state;

		return (
			<div>
				<DateSelector
					title="Select Last Activity Date:"
					selectedDate={lastActivityDate ? new Date(lastActivityDate) : new Date()}
					onChange={onLastActivityDateChanged}
				/>
			</div>
		);
	};

	onLastActivityDateChanged = date => {
		this.update({
			lastActivityDate: date
		});
	};

	renderClients = () => {
		let { assignedClients } = this.state;

		return <ClientAssigner onChange={this.onAssignedClientsChanged} clients={assignedClients} />;
	};

	onAssignedClientGroupsChanged = async ({ selectedClientGroups }) => {
		await this.update({
			assignedClientGroups: selectedClientGroups
		});
	};

	renderClientGroups = () => {
		let { assignedClientGroups } = this.state;

		return <ClientGroupAssigner onChange={this.onAssignedClientGroupsChanged} clientGroups={assignedClientGroups} />;
	};

	onColumnSelected = async ({ column }) => {
		let { selectedColumns } = this.state;

		if (typeof selectedColumns[column.id] !== "undefined") {
			delete selectedColumns[column.id];
		} else {
			selectedColumns[column.id] = true;
		}

		await this.update({
			selectedColumns
		});
	};

	renderColumns = () => {
		let { selectedColumns } = this.state;
		let columns = Object.values(WORKFLOW.columns);

		return (
			<div className="pill-container pill-container--filter-view">
				{columns.map((column, index) => {
					return (
						<div
							className={clsx("pill", {
								"pill--active": selectedColumns[column.id]
							})}
							onClick={() => this.onColumnSelected({ column })}
							key={index}
						>
							{column.display}
						</div>
					);
				})}
			</div>
		);
	};

	onMenuItemClicked = ({ menuItem }) => {
		this.update({
			selectedMenuItem: menuItem
		});
	};

	render() {
		let { show, selectedMenuItem, menuItems } = this.state;
		let { onClose } = this.props;
		let { renderDates, renderUsers, renderTeams, renderClients, renderClientGroups, renderFlowStateFilters, renderColumns, renderLastActivity } = this;

		return (
			<Modal
				open={show}
				onClose={onClose}
				center
				classNames={{
					modal: "engagement-filter-modal__modal"
				}}
			>
				<div>
					<div className="modal-title">Filters</div>

					<div className="engagement-filter-modal">
						<div className="engagement-filter-modal__menu">
							{menuItems.map((menuItem, index) => {
								return (
									<div
										onClick={() => this.onMenuItemClicked({ menuItem })}
										className={`engagement-filter-modal__menu__item ${
											selectedMenuItem === menuItem ? "engagement-filter-modal__menu__item--selected" : ""
										}`}
										key={`${index}-${menuItem}`}
									>
										{menuItem}
									</div>
								);
							})}
						</div>
						<div className="engagement-filter-modal__body">
							{selectedMenuItem === "Dates" && renderDates()}
							{selectedMenuItem === "Users" && renderUsers()}
							{selectedMenuItem === "Teams" && renderTeams()}
							{selectedMenuItem === "Clients" && renderClients()}
							{selectedMenuItem === "Client Groups" && renderClientGroups()}
							{selectedMenuItem === "Status" && renderFlowStateFilters()}
							{selectedMenuItem === "Columns" && renderColumns()}
							{selectedMenuItem === "Last Activity" && renderLastActivity()}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<Button view="secondary" onClick={onClose} text="Cancel" />
					<Button onClick={this.onApply} text="Apply" />
				</div>
			</Modal>
		);
	}
}
