import React from "react";
import * as Icon from "react-feather";

class EngagementTaskCard extends React.Component {
	render() {
		const { task, index, onRemoveTask, onUpdateEngTask } = this.props;

		return (
			<div key={index}>
				<div
					className="etm__tasks__task-card__details"
					onClick={() => {
						onUpdateEngTask(index);
					}}
				>
					<span className="etm__tasks__task-card__title">{task.name}</span>

					<div className="etm__tasks__task-card__assigned-to">
						<span className="etm__tasks__task-card__assigned-to-txt">Assigned to:</span>
						<span className="card__title"> {task.assignedUser.name}</span>
					</div>

					<Icon.XCircle
						className="etm__tasks__task-card__remove-task-icon"
						size={28}
						color="#FF6B6B"
						onClick={e => {
							e.stopPropagation();
							onRemoveTask(index);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default EngagementTaskCard;
