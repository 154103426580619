import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";

import TextAreaField from "../common/text_area_field/TextAreaField";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import RolePicker from "../common/role_picker/RolePicker";
import SearchableDropdown from "../common/searchable_dropdown/SearchableDropdown";

import "../common/modal/modal.css";
import "./team-modal.css";
import WComponent from "../common/WComponent";
import { toast as alert } from "react-toastify";
import Button from "../common/button/Button";

class TeamModal extends WComponent {
	constructor() {
		super();
		this.state = {
			teamId: "new",

			loading: false,
			show: false,

			name: "",
			description: "",
			teamUsers: []
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show || prevProps.teamId !== this.props.teamId) {
			this.update({ show });

			if (show) {
				this.resetComponent();
			}
		}
	}

	async resetComponent() {
		let { teamId } = this.props;

		await this.update({
			loading: true,
			teamId,
			name: "",
			description: "",
			teamUsers: []
		});

		if (teamId !== "new") {
			await this.fetchTeam();
		}

		await this.update({
			loading: false
		});
	}

	fetchTeam = async () => {
		let { teamId } = this.props;

		let { id, name, description, users } = await UserService.fetchTeam({ teamId });

		await this.update({
			teamId: id,
			name,
			description,
			teamUsers: users
		});
	};

	show() {
		this.update({
			show: true
		});
	}

	updateTeam = async () => {
		let { teamId, name, description, teamUsers } = this.state;

		alert.info("Updating ...");

		this.update({
			loading: true
		});

		let success = await UserService.updateTeam({ teamId, name, description, users: teamUsers });

		if (!success) {
			alert.info("Failed to update team.");
		} else {
			alert.info("Updated team successfully!");
		}

		this.update({
			loading: false
		});
	};

	isValid = () => {
		let { name } = this.state;

		return name.length > 0;
	};

	createTeam = async () => {
		let { name, description, teamUsers } = this.state;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		this.update({
			loading: true
		});

		let success = await UserService.createTeam({ name, description, users: teamUsers });

		if (!success) {
			alert.info("Failed to create team.");
		} else {
			alert.info("Created team successfully!");
		}

		this.update({
			loading: false
		});
	};

	isNewTeam = () => {
		return this.state.teamId === "new";
	};

	onChange = async e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		await this.update({
			[target.name]: value
		});
	};

	onClose = async confirmed => {
		if (confirmed) {
			if (this.isNewTeam()) {
				await this.createTeam();
			} else {
				await this.updateTeam();
			}
		}

		await this.update({
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSelectUser = async user => {
		let { teamUsers, teamId } = this.state;

		teamUsers.push({
			user_id: user.id,
			team_id: teamId,
			role_id: 0,

			// render part
			userName: user.name,
			roleName: "Staff"
		});

		await this.update({ teamUsers });
	};

	onRemoveMember = async index => {
		let { teamUsers } = this.state;

		teamUsers.splice(index, 1);
		let newTeamUsers = teamUsers.slice();

		await this.update({
			teamUsers: newTeamUsers
		});
	};

	onSelectRole = async (userIndex, role) => {
		let { teamUsers } = this.state;

		teamUsers = teamUsers.slice();

		teamUsers[userIndex].role_id = role.id;
		teamUsers[userIndex].roleName = role.name;

		await this.update({
			teamUsers
		});
	};

	renderMembers = () => {
		let { teamUsers } = this.state;
		let { onRemoveMember, onSelectUser, onSelectRole } = this;

		return (
			<div className="team__members">
				<SearchableDropdown title="Add a member ..." onSelect={onSelectUser} type={"users"} clearOnSelect showAll />
				<div className="team__members__title">Current Team Members:</div>
				{teamUsers.length > 0 && (
					<div className="team__members__list">
						{teamUsers.map((teamUser, index) => {
							return (
								<div className="team__members__list__item">
									<div className="team__members__list__item__txt">
										{teamUser.userName}
										<RolePicker roleId={teamUser.roleName} onSelect={role => onSelectRole(index, role)} />
									</div>
									<Icon.XCircle
										size={28}
										color="#FF6B6B"
										onClick={() => {
											onRemoveMember(index);
										}}
									/>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	};

	render() {
		let { show, name, description, loading } = this.state;

		return (
			<Modal open={show} onClose={() => this.onClose(false)} center>
				<div>
					<div className="modal-title">{this.isNewTeam() ? "New Team" : name}</div>

					{loading ? (
						<div className="loader">Loading ...</div>
					) : (
						<div className="team-modal__body">
							<TextFieldInput title="Team Name" name="name" value={name} onChange={this.onChange} autoComplete="off" />
							<TextAreaField title="Description" type="text" name="description" value={description} onChange={this.onChange} />

							{this.renderMembers()}
						</div>
					)}
				</div>

				<div className="modal-footer">
					<Button onClick={() => this.onClose(true)} text={this.isNewTeam() ? "Create Team" : "Update Team"} />

					<Button onClick={() => this.onClose(false)} text="Cancel" />
				</div>
			</Modal>
		);
	}
}

export default TeamModal;
