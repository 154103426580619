import Kichiri from "./KichiriService";
import UserService from "./UserService";

const AuthService = {
	/**
	 * Logins user into the application
	 *
	 * @param {String} email
	 * @param {String} password
	 */
	async login({ email, password }) {
		try {
			let response = await Kichiri.user.login({
				email,
				password
			});

			UserService.storeUserData(response.data);
		} catch (error) {
			console.log(error);
		}
	},
	/**
	 * Logout user out of the application
	 * @returns {Object}
	 */
	async logout() {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.user.logout({}, {}, userData.authToken);
			UserService.clearUserData();
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Validates the user token
	 * @returns {void}
	 */
	async validateToken() {
		let userData = UserService.getUserData();
		try {
			await Kichiri.user.validateToken({}, {}, userData.authToken);
		} catch (error) {
			console.log(error);
		}
	}
};

export default AuthService;
