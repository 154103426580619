import React from "react";
import DatePicker from "react-datepicker";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";
import * as Icon from "react-feather";
import clsx from "clsx";

import { STATUSES } from "../../../constants/Tasks";

import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import UserService from "../../../services/UserService";
import ClientService from "../../../services/ClientService";
import TaskService from "../../../services/TaskService";

import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../common/text_area_field/TextAreaField";
import Checkbox from "../../common/checkbox/Checkbox";
import WComponent from "../../common/WComponent";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import DeleteConfirmationModal from "../../common/modal/confirmation_modal/ConfirmationModal";
import DateSelector from "../../common/date_selector/DateSelector";
import Button from "../../common/button/Button";

import "./task-modal.css";
import "../../common/modal/modal.css";

class TaskModal extends WComponent {
	constructor() {
		super();
		this.state = {
			taskId: "new",
			engagementId: 0,
			clientId: 0,

			name: "",
			description: "",
			deadline: new Date().toDateString(),
			// duration: '',
			notes: "",
			notify: false,
			status: "pending",
			createdBy: 0,
			read: false,

			loading: false,
			show: false,

			selectedClientName: "",
			selectedStatus: "",

			assignedUsers: [],
			showDeleteConfirmation: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { taskId, clientId, show } = this.props;

		await this.update({
			loading: true
		});

		await this.update({
			// Toggles
			show,

			// Task Data
			taskId,
			clientId: clientId || 0,
			engagementId: 0,
			name: "",
			description: "",
			deadline: new Date().toDateString(),
			// duration: '',
			notes: "",
			status: "pending",
			notify: false,

			// Display Items
			selectedClientName: "",
			selectedStatus: "",

			assignedUsers: [],
			showDeleteConfirmation: false
		});

		if (taskId !== "new") {
			await this.fetchTask();
		}

		if (clientId) {
			let client = await ClientService.fetchClient({ clientId });

			await this.update({
				selectedClientName: client.client.name
			});
		}

		await this.update({
			loading: false
		});
	};

	async fetchTask() {
		let { taskId } = this.state;

		let taskIdIntialState = 0;
		if (taskId === taskIdIntialState) {
			return;
		}

		try {
			let task = await TaskService.fetchTask({ taskId });

			let assignedUsers = task.task_users.map(tu => {
				let roleName = tu.Role ? tu.Role.name : "No Role";

				return {
					user_id: tu.user_id,
					role_id: tu.role_id,

					userName: `${tu.User.first_name} ${tu.User.last_name}`,
					roleName,
					should_notify: tu.notifications_only
				};
			});

			let engagement = null;

			if (task.Engagement) {
				engagement = {
					name: task.Engagement.name,
					id: task.engagement_id
				};
			}

			await this.update({
				// Task Data
				name: task.name,
				engagementId: task.engagement_id,
				clientId: task.client_id,

				description: task.description,
				deadline: task.deadline,
				// duration: task.duration,
				notes: task.notes,
				status: task.status,
				createdBy: task.created_by,
				notify: task.notify,
				read: task.read,

				// Display Items
				selectedClientName: task.Client ? task.Client.name : "",
				selectedStatus: STATUSES[task.status].name,

				assignedUsers,

				engagement
			});
		} catch (error) {
			console.log(error);
		}
	}

	updateTask = async () => {
		let { taskId, clientId, name, description, deadline, notes, status, notify, assignedUsers } = this.state;
		let { engagementId } = this.props;

		alert.info("Updating ...");

		await this.update({
			loading: true
		});

		let task = null;

		try {
			task = await TaskService.update({ taskId, name, engagementId, clientId, description, deadline, notes, status, notify, assignedUsers });

			alert.success("Updated Successfully!");
		} catch (error) {
			console.log(error);
			alert.error("Updated error ...");
		}

		await this.update({
			loading: false
		});

		return task;
	};

	isValid = () => {
		let { name, clientId, description, deadline } = this.state;

		return name.length > 0 && clientId !== 0 && description.length > 0 && deadline != null;
	};

	createTask = async () => {
		let { name, assignedUsers, clientId, description, deadline, notes, status, notify } = this.state;
		let { engagementId } = this.props;

		let created = false;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return created;
		}

		await this.update({
			loading: true
		});

		try {
			await TaskService.create({ name, engagementId, assignedUsers, clientId, description, deadline, notes, status, notify });

			created = true;
			alert.success("Created Successfully!");
		} catch (error) {
			alert.error("Create Error ...");
		}

		await this.update({
			loading: false
		});

		return created;
	};

	isNewTask = () => {
		let { taskId } = this.state;
		return taskId === "new";
	};

	onChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;

		this.update({
			[target.name]: value
		});
	};

	onStatusSelect = status => {
		this.update({
			status: status.id,
			selectedStatus: status.name
		});
	};

	onClose = async ({ confirmed }) => {
		let task = null;

		if (confirmed) {
			if (this.isNewTask()) {
				let isCreated = await this.createTask();

				if (!isCreated) {
					return;
				}
			} else {
				task = await this.updateTask();
			}
		}

		if (this.props.onClose) {
			this.props.onClose({ task });
		}
	};

	onDeadlineChange = date => {
		this.update({
			deadline: date
		});
	};

	onClientSelect = client => {
		this.update({
			clientId: client.id,
			selectedClientName: client.name
		});
	};

	deleteTask = async taskId => {
		const { engagementId } = this.state;

		try {
			await TaskService.update({ taskId, engagementId, isDeleted: true });
			this.resetComponent();
		} catch (error) {
			alert.error("Task Delete Error");
			console.error(error);
		}
	};

	onClickDelete = async () => {
		this.update({
			showDeleteConfirmation: true
		});
	};
	onClickDeleteConfirm = async ({ confirmed, proxyState }) => {
		await this.update({ showDeleteConfirmation: false });

		if (confirmed === false) {
			return;
		}

		const { taskId } = this.state;
		await this.deleteTask(taskId);
		this.onClose({ confirmed: true });
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		let newAssignedUsers = selectedUsers.slice();

		await this.update({
			assignedUsers: newAssignedUsers
		});
	};

	render() {
		let {
			// Render state variables
			show,
			loading,

			// Data state variables
			name,
			description,
			deadline,
			// notes,
			createdBy,
			notify,
			assignedUsers,
			// read,

			// Temp data render state variables
			selectedStatus,
			selectedClientName,

			engagement
		} = this.state;
		let userData = UserService.getUserData();

		return (
			<>
				<Modal open={show} onClose={() => this.onClose({ confirmed: false })} center>
					<div className="task-modal">
						<div className="modal-title">{this.isNewTask() ? "New Task" : name}</div>

						<div className="task-modal__body">
							{loading && <div className="loader">Loading ...</div>}
							{!loading && (
								<>
									{engagement && <div className="task-modal__body__engagement">Engagement: {engagement.name}</div>}

									<SearchableDropdown
										containerClass="task-modal__body__input"
										title="Client"
										onSelect={this.onClientSelect}
										type={"clients"}
										selectedName={selectedClientName}
									/>

									<TextFieldInput
										containerClass="task-modal__body__input"
										title="Task Name"
										name="name"
										onChange={this.onChange}
										value={name}
										autoComplete="off"
									/>

									<TextAreaField
										containerClass="task-modal__body__input"
										title="Description"
										name="description"
										value={description}
										onChange={this.onChange}
										autoComplete="off"
									/>

									<SearchableDropdown
										containerClass="task-modal__body__input"
										title="Status"
										onSelect={this.onStatusSelect}
										type={"taskStatuses"}
										selectedName={selectedStatus}
										showAll
									/>

									<DateSelector
										containerClass="task-modal__body__input"
										title="Deadline"
										selectedDate={new Date(deadline)}
										onDateSelect={this.onDeadlineChange}
										dateFormat={"MMMM d, yyyy h:mm aa"}
										showTimeSelect={true}
									/>

									{/* <TextAreaField title="Notes" type="text" name="notes" value={notes} onChange={this.onChange} /> */}
									{(userData.userId === createdBy || this.isNewTask()) && (
										<Checkbox
											containerClass="task-modal__body__input"
											title="Notify me when this task is completed"
											name="notify"
											checked={notify}
											onChange={this.onChange}
										/>
									)}

									{/* <TextAreaField title="Notes" type="text" name="notes" value={notes} onChange={this.onChange} /> */}

									<UserAssigner onChange={this.onAssignedUsersChanged} users={assignedUsers} showNotificationToggle />
								</>
							)}
						</div>
					</div>

					<div className="modal-footer">
						{!this.isNewTask() && <Button view="secondary" icon={<Icon.Trash2 size={18} />} onClick={this.onClickDelete} />}

						<Button text="Cancel" view="secondary" onClick={() => this.onClose({ confirmed: false })} />

						<Button text={this.isNewTask() ? "Create" : "Update"} onClick={() => this.onClose({ confirmed: true })} />
					</div>
				</Modal>

				<DeleteConfirmationModal
					title="Delete Task?"
					warning="Are you sure you want to delete this task?"
					show={this.state.showDeleteConfirmation}
					onClose={this.onClickDeleteConfirm}
				/>
			</>
		);
	}
}

export default TaskModal;
