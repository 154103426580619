import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";
import { toast as alert } from "react-toastify";

import TextAreaField from "../../common/text_area_field/TextAreaField";
import NoteService from "../../../services/NoteService";
import WComponent from "../../common/WComponent";
import Button from "../../common/button/Button";

import "./note-modal.css";

class NoteModal extends WComponent {
	constructor() {
		super();
		this.state = {
			noteId: "new",
			content: "",

			loading: false,
			show: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { noteId } = this.props;

		if (prevProps.noteId !== noteId) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { noteId } = this.props;

		await this.update({
			loading: true,
			noteId,
			content: ""
		});

		if (noteId !== "new") {
			await this.fetchNote();
		}

		await this.update({
			loading: false
		});
	}

	fetchNote = async () => {
		let { noteId } = this.props;

		let note = await NoteService.fetchNote({ noteId });

		await this.update({ noteId: note.id, content: note.content });
	};

	show() {
		this.update({
			show: true
		});
	}

	updateNote = async () => {
		let { noteId, content } = this.state;

		alert.info("Updating ...");

		this.update({
			loading: true
		});

		let success = await NoteService.updateNote({ noteId, content });

		if (!success) {
			alert.info("Failed to update note.");
		} else {
			alert.info("Updated note successfully!");
		}

		this.update({
			loading: false
		});
	};

	isValid = () => {
		let { content } = this.state;

		return content.length > 0;
	};

	createNote = async () => {
		let { content } = this.state;
		let { engagementId, clientId, shouldPin } = this.props;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		this.update({
			loading: true
		});

		let success = await NoteService.createNote({ content, engagementId, clientId, isPinned: shouldPin });

		if (!success) {
			alert.info("Failed to create note.");
		} else {
			alert.info("Created note successfully!");
		}

		this.update({
			loading: false
		});
	};

	isNewNote = () => {
		return this.state.noteId === "new";
	};

	onDeleteTask = async () => {
		let { noteId } = this.state;

		this.update({
			loading: true
		});

		let success = await NoteService.updateNote({ noteId, status: "deleted" });

		if (!success) {
			alert.info("Failed to delete note.");
		} else {
			alert.info("Deleted note successfully!");
		}

		this.update({
			loading: false,
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onChange = async e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		await this.update({
			[target.name]: value
		});
	};

	onClose = async confirmed => {
		let { isNewNote, createNote, updateNote } = this;
		let { onClose } = this.props;

		if (confirmed) {
			if (isNewNote()) {
				await createNote();
			} else {
				await updateNote();
			}
		}

		await this.update({
			show: false
		});

		if (onClose) {
			onClose();
		}
	};

	render() {
		let { isNewNote } = this;
		let { show, content, loading } = this.state;

		return (
			<Modal classNames={{ modal: "note-modal" }} open={show} onClose={() => this.onClose(false)} center>
				<div>
					<div className="note-modal__title">{isNewNote() ? "New Note" : "Note"}</div>

					<div className="note-modal__body">
						{loading && <div className="loader">Loading ...</div>}
						{!loading && <TextAreaField className="note-modal__body__input" type="text" name="content" value={content} onChange={this.onChange} />}
					</div>
				</div>
				<div className="note-modal__footer">
					{!isNewNote() && (
						<Button
							view="secondary"
							className="note-modal__footer__delete-btn"
							onClick={this.onDeleteTask}
							icon={<Icon.Trash2 size={22} />}
						></Button>
					)}

					<Button view="secondary" text="Cancel" onClick={() => this.onClose(false)} />

					<Button text={isNewNote() ? "Create Note" : "Update Note"} onClick={() => this.onClose(true)} />
				</div>
			</Modal>
		);
	}
}

export default NoteModal;
