import React from "react";
import * as Icon from "react-feather";

import WComponent from "../WComponent";
import SearchableDropdown from "../searchable_dropdown/SearchableDropdown";
import RolePicker from "../role_picker/RolePicker";

import "./user-assigner.css";
import UtilityService from "../../../services/UtilityService";
import Checkbox from "../checkbox/Checkbox";
import clsx from "clsx";

class UserAssigner extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedUsers: JSON.parse(JSON.stringify(props.users))
		};
	}

	componentDidUpdate(prevProps) {
		let { users } = this.props;

		if (prevProps.users.length !== users.length) {
			let selectedUsers = JSON.parse(JSON.stringify(users));

			this.update({
				selectedUsers
			});
		}
	}

	async componentDidMount() {}

	onSelectUser = async user => {
		let { selectedUsers } = this.state;

		let selectedUserRoleId = UtilityService.isNullish(user.defaultRole) ? 0 : user.defaultRole.id;
		let selectedUserRoleName = UtilityService.isNullish(user.defaultRole) ? "Staff" : user.defaultRole.name;

		selectedUsers.push({
			user_id: user.id,
			role_id: selectedUserRoleId,

			// render part
			userName: user.name,
			roleName: selectedUserRoleName,
			should_notify: false
		});

		await this.update({ selectedUsers });

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onRemoveMember = async index => {
		let { selectedUsers } = this.state;

		selectedUsers.splice(index, 1);
		let newSelectedUsers = selectedUsers.slice();

		await this.update({
			selectedUsers: newSelectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onSelectRole = async (userIndex, role) => {
		let { selectedUsers } = this.state;

		selectedUsers = selectedUsers.slice();

		selectedUsers[userIndex].role_id = role.id;
		selectedUsers[userIndex].roleName = role.name;

		await this.update({
			selectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onNotificationChange = async ({ event, user, index }) => {
		let { selectedUsers } = this.state;

		selectedUsers = selectedUsers.slice();
		selectedUsers[index].should_notify = event.target.checked;

		await this.update({
			selectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	render() {
		let { selectedUsers } = this.state;
		let { hideRolePicker, showNotificationToggle, containerClass } = this.props;

		return (
			<div className={clsx("user-assigner", containerClass)}>
				<SearchableDropdown title="Add a member ..." onSelect={this.onSelectUser} type={"users"} clearOnSelect />
				<div className="user-assigner__title">Current Users:</div>

				{selectedUsers.length > 0 ? (
					<div className="user-assigner__list">
						{selectedUsers.map((user, index) => {
							return (
								<div key={index} className="user-assigner__list__item">
									<div>
										<span className="user-assigner__list__item__user-name">{user.userName}</span>
										{!hideRolePicker && <RolePicker roleId={user.roleName} onSelect={role => this.onSelectRole(index, role)} />}
										{showNotificationToggle && (
											<Checkbox
												style={{ marginTop: 0 }}
												isSmall
												title="Notify"
												name="notify"
												checked={user.should_notify}
												onChange={event => this.onNotificationChange({ event, user, index })}
											/>
										)}
									</div>

									<Icon.XCircle
										className="user-assigner__list__item__icon"
										size={28}
										color="#FF6B6B"
										onClick={() => {
											this.onRemoveMember(index);
										}}
									/>
								</div>
							);
						})}
					</div>
				) : (
					<div className="user-assigner__no-users">No users added.</div>
				)}
			</div>
		);
	}
}

export default UserAssigner;
